import React, { Fragment } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useIntl } from 'gatsby-plugin-intl';

import { useTheme } from 'styles/theme';

import { Mate, CircleBorder, Details, Description, PositionWrapper, Position, RoundPhoto, Name } from './styles';
import { MemberProps } from './types';

export default ({ image, member, extended, ...props }: MemberProps) => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);

  return (
    <Mate
      extended={extended}
      {...props}
    >
      {image ? (
        <CircleBorder extended={extended}>
          <RoundPhoto fixed={image} />
        </CircleBorder>
      ) : null}
      <Details extended={extended}>
        <Name
          size='regular'
          component='h3'
        >
          {formatMessage({ id: `team.${member.staff}.${member.key}.name` })}
        </Name>
        <PositionWrapper extended={extended}>
          <Position
            size={isDesktop ? (extended ? 'title' : 'regular') : 'title'}
            component='p'
            color='main'
          >
            {formatMessage({ id: `team.${member.staff}.${member.key}.position` })}
          </Position>
        </PositionWrapper>
        <Description
          open={Boolean(extended)}
          textAlignToLeft={member.key === 'beatrice_fischer'}
        >
          {/**
           * Reserved characters: • and + in src/translations/{en/de}.json / team
           * Split the text by the '•' character to fake a list
           * and the '+' character to break lines
           * */}
          {formatMessage({ id: `team.${member.staff}.${member.key}.text` })
            .split('•')
            .map((text, index) => (
              <p key={text}>
                {index > 0 && (
                  <span style={{ color: theme.palette.primary.main, fontSize: '12px', marginLeft: '-9px' }}>•</span>
                )}
                {text.split('+').map((t, i) => (
                  <Fragment key={i}>
                    {t}
                    {i < text.split('+').length - 1 && <br />}
                  </Fragment>
                ))}
              </p>
            ))}
        </Description>
      </Details>
    </Mate>
  );
};

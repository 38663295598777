import styled, { theme } from '../../../../styles/theme'


export const extensionPictureStyles = {
  position: 'absolute',
  bottom: 0,
}

export const extensionPictureMediaQuery = `
  (min-width: ${theme.breakpoints.xxl}px)
`

export const ExtensionImageWrapper = styled.div`
  ${({ theme }) => `
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border-width: ${theme.spacing.base * 1.25}px;
    border-color: ${theme.palette.secondary.light};
    
    padding: ${theme.spacing.base * 3.375}px;
    background-color: ${theme.palette.secondary.light};
    position: relative;
    background-clip: content-box;
    border-style: solid;
    margin: ${theme.spacing.base * 2.5}px auto;

    & .gatsby-image-wrapper {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    @media (min-width: ${theme.breakpoints.md}px) {
      margin: 0 auto;
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      flex-shrink: 0;
      align-self: center;
    }

    @media (min-width: ${theme.breakpoints.xxl}px) {
      width: 445px;
      height: 445px;
      margin: ${theme.spacing.base * 3}px;
    }
  `}
`

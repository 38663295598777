import React, { useState, useCallback, useEffect } from 'react'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { useIntl } from 'gatsby-plugin-intl'

import CookiesDialog from './CookiesDialog'
import { CookiesButton, Modal } from './styles'


export default () => {
  const [ open, setOpen ] = useState(false)
  const [ cookiesDialog, setCookiesDialog ] = useState(false)
  const handleClick = useCallback(() => {
    setOpen(!open)
  }, [open])
  const handleClose = () => setOpen(false)
  const handleAccept = useCallback(() => {
    if (window) {
      localStorage.setItem('noCookies', 'hide')
      setCookiesDialog(true)
    }
    setOpen(false)
  }, [])
  useEffect(() => {
    let cookies
    if (window) {
      cookies = localStorage.getItem('noCookies')
      cookies === 'hide' && setCookiesDialog(true)
    }
  }, [])
  const { formatMessage } = useIntl()

  return (
    !cookiesDialog ? <>
      <CookiesButton onClick={handleClick}>{formatMessage({ id: 'cookies.toggle-button' })}</CookiesButton>
      <Modal
        aria-labelledby={formatMessage({ id: 'cookies.aria-label' })}
        aria-describedby={formatMessage({ id: 'cookies.aria-description' })}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          transitionDuration: {
            enter: 400,
            exit: 400,
          }
        }}
      >
        <Fade in={open} timeout={0}>
          <CookiesDialog onStop={handleClose} onAccept={handleAccept} />
        </Fade>
      </Modal>
    </>
    :
    null
  )
}

import React, { ReactElement } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useIntl } from 'gatsby-plugin-intl'

import Container from '../../../components/Container'
import Title, { IntroCentered } from '../../../components/Title'
import { useTheme } from '../../../styles/theme'

import {
  MobileWrapper,
  DesktopWrapper,
  LogoWrapper,
  Spacer,
} from './styles'

export default (): ReactElement => {
  const imagesData = useStaticQuery(graphql`
    query {
      logo: imageSharp(fixed: { originalName: { eq: "e-procurement-logo.png" } }) {
        fixed(quality: 92, width: 200) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile1en: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-01-en.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile2en: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-02-en.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile3en: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-03-en.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile4en: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-04-en.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile5en: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-05-en.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile6en: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-06-en.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile7en: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-07-en.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile8en: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-08-en.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile9en: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-09-en.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile1de: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-01-de.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile2de: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-02-de.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile3de: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-03-de.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile4de: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-04-de.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile5de: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-05-de.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile6de: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-06-de.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      mobile7de: imageSharp(fixed: { originalName: { eq: "e-procurement-presentation-mobile-07-de.png" } }) {
        fixed(quality: 92, width: 255) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      presentationDesktopEN: imageSharp(fluid: { originalName: { eq: "e-procurement-presentation-desktop-en.png" } }) {
        fluid(quality: 92, maxWidth: 1220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      presentationDesktopDE: imageSharp(fluid: { originalName: { eq: "e-procurement-presentation-desktop-de.png" } }) {
        fluid(quality: 92, maxWidth: 1220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  `)
  const { formatMessage, locale } = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md - 1}px)`)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`)

  return (
    <Container>
      <Title size="regular" center component="h3">{formatMessage({ id: 'product.e-procurement.title' })}</Title>
      <IntroCentered>{formatMessage({ id: 'product.e-procurement.intro' })}</IntroCentered>
      {isMobile &&
        <MobileWrapper>
          <LogoWrapper>
            <Image fixed={imagesData.logo.fixed} />
          </LogoWrapper>
          <Image fixed={imagesData[`mobile1${locale}`].fixed} />
          <Image fixed={imagesData[`mobile2${locale}`].fixed} />
          <Image fixed={imagesData[`mobile3${locale}`].fixed} />
          <Image fixed={imagesData[`mobile4${locale}`].fixed} />
          <Image fixed={imagesData[`mobile5${locale}`].fixed} />
          <Image fixed={imagesData[`mobile6${locale}`].fixed} />
          <Image fixed={imagesData[`mobile7${locale}`].fixed} />
        </MobileWrapper>
      }
      {isDesktop &&
        <DesktopWrapper>
          <Image
            fluid={imagesData[`presentationDesktop${locale.toUpperCase()}`].fluid}
          />
        </DesktopWrapper>
      }
    </Container>
  )
}

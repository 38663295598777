/* eslint-disable max-len */

export const colors = {
  brand: "#663399",
  lilac: "#9d7cbf",
  accent: "#ffb238",
  success: "#37b635",
  warning: "#ec1818",
  ui: {
    bright: "#e0d6eb",
    light: "#f5f3f7",
    whisper: "#fbfafc",
  },
  code: "#fcf6f0",
  gray: {
    dark: "hsla(270, 17.119554496%, 0%, 0.92)",
    copy: "hsla(270, 15.797828016000002%, 0%, 0.88)",
    calm: "rgba(0, 0, 0, 0.54)",
  },
  white: "#fff",
  black: "#000",
}

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1440,
}

export const widths = {
  md: 720,
  lg: 960,
  xl: 1140,
}

export const dimensions = {
  fontSize: {
    regular: 16,
    large: 18,
  },
  headingSizes: {
    h1: 2.441,
    h2: 1.953,
    h3: 1.563,
    h4: 1.25,
  },
  lineHeight: {
    regular: 1.45,
    heading: 1.2,
  },
  containerPadding: 1.5,
}

export const buttonsDimensions = {
  regular: 150,
}

export const heights = {
  header: 60,
  footer: {
    desktop: 260,
    mobile: 750,
  }
}

export const zIndex = {
  overlay: 1300,
  navbar: 1301,
}

export const gradients = [
  'linear-gradient(0deg, rgba(34, 168, 161, 0.7) 0%, rgba(101, 196, 192, 0.7) 47%, rgba(142, 214, 212, 0.7) 83%, rgb(158, 221, 219, 0.7) 100%)',
  'linear-gradient(180deg, #22a8a1 0%, #65c4c0 47%, #8ED6D4 83%, #9edddb 100%)',
  'linear-gradient(0deg, rgba(34, 168, 161, 0.7) 0%, rgba(101, 196, 192, 0.7) 47%, rgba(142, 214, 212, 0.7) 70%, rgb(255, 255, 255) 85%, rgb(255, 255, 255) 100%)',
  'linear-gradient(180deg, rgba(34, 168, 161, 0.7) 0%, rgba(101, 196, 192, 0.7) 25%, rgba(142, 214, 212, 0.7) 75%, rgb(255, 255, 255) 100%)',
]

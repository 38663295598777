import React, { ReactElement } from 'react'

import Hero from './containers/Hero'
import YourBenefits from './containers/YourBenefits'
import Product from './containers/Product'
import ArtificialIntelligence from './containers/ArtificialIntelligence'
// import CovidPandemic from './containers/CovidPandemic'
import Team from './containers/Team'
import Contacts from './containers/Contacts'


type StructureItemType = {
  hash: string,
  start?: boolean,
  menu?: string,
  element: ReactElement,
  wrapper?: 'header' | 'footer' | 'section' | 'main' | 'div',
}

const homepageStructure: StructureItemType[] = [
  {
    hash: 'intro',
    start: true,
    element: <Hero />,
  },
  {
    hash: 'your-benefits',
    menu: 'your-benefits',
    element: <YourBenefits />,
  },
  {
    hash: 'product',
    menu: 'product',
    element: <Product />,
  },
  {
    hash: 'artificial-intelligence',
    menu: 'ai',
    element: <ArtificialIntelligence />,
  },
  // {
  //   hash: 'covid-19',
  //   menu: 'covid',
  //   element: <CovidPandemic />,
  // },
  {
    hash: 'team',
    menu: 'team',
    element: <Team />,
  },
  {
    hash: 'contacts',
    menu: 'contacts',
    element: <Contacts />,
  },
]

export default homepageStructure

const tuple = <T extends string[]>(...args: T) => args
const hashes: string[] = []
homepageStructure.map(({ hash }) => hashes.push(hash))
const hashesTuple = tuple(...hashes)
export type MenuHashesType = typeof hashesTuple[number]

import React, { useState } from 'react'
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { MenuLogo } from '../../components/Logo'
import { useTheme } from '../../styles/theme'

import { MenuWrapper, MenuContainer, MenuBar } from './styles'
import MenuDesktop from './MenuDesktop'
import MenuMobile from './MenuMobile'
import { MenuProps } from './types'


export default ({ originalPath }: MenuProps) => {
  const [ isOpen, setOpen ] = useState(false)
  const handleClose = () => setOpen(false)
  const handleOpen = () => setOpen(true)
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.lg - 1}px)`)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`)
  
  return (
    <MenuWrapper>
      <MenuContainer>
        <MenuLogo onClick={handleClose} />
        <MenuBar>
          {isMobile && (
            <MenuMobile
              open={isOpen}
              handleClose={handleClose}
              handleOpen={handleOpen}
              setOpen={setOpen}
              originalPath={originalPath}
            />
          )}
          {isDesktop && <MenuDesktop originalPath={originalPath} />}
        </MenuBar>
      </MenuContainer>
    </MenuWrapper>
  )
}

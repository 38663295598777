import React, { useCallback } from 'react'
import { Link } from 'gatsby-plugin-intl'
import { animateScroll } from 'react-scroll'

import {LogoLight} from './Logo'

import { FooterLogoWrapper } from './styles'


export default () => {
  const clickHandler = useCallback((e) => {
    e.preventDefault()
    animateScroll.scrollToTop()
  }, [])
  
  return (
    <FooterLogoWrapper>
      <Link to="/" onClick={clickHandler}>
        <LogoLight />
      </Link>
    </FooterLogoWrapper>
  )
}

import React, { HTMLAttributes } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Container from '../../components/Container'
import Title from '../../components/Title'
import { useTheme } from '../../styles/theme'

import Form from './components/Form'
import {
  Wrapper,
  Paragraph,
  InnerWrapper,
  RobotAndContacts,
  ContactDetails,
  decorationStyles1,
  decorationStyles2,
} from './styles'
import data from './contactsData'


export default (props: HTMLAttributes<HTMLElement>) => {
  const images = useStaticQuery(graphql`
    query Robot {
      mobile: imageSharp(
        fluid: { originalName: { eq: "contacts-robot-phone.png" } }
      ) {
        fluid(quality: 92, maxWidth: 625) {
          ...GatsbyImageSharpFluid_tracedSVG
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      deco1: imageSharp(fixed: { originalName: { eq: "deco1.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco2: imageSharp(fixed: { originalName: { eq: "deco2.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  `)
  const { formatMessage } = useIntl()
  const theme = useTheme();
  const decorative = useMediaQuery(
    `(min-width: ${theme.breakpoints.xxl + 120}px)`
  )

  return (
    <Wrapper {...props}>
      <Container>
        <Title center>{formatMessage({ id: 'contact.title' })}</Title>
        <InnerWrapper>
          <RobotAndContacts>
            <Image fluid={images.mobile.fluid} />
            <ContactDetails>
              <Paragraph type="phone">{data.phone}</Paragraph>
              <Paragraph type="email">{data.email}</Paragraph>
            </ContactDetails>
          </RobotAndContacts>
          <Form />
        </InnerWrapper>
        {decorative && (
          <>
            <Image fixed={images.deco1.fixed} css={decorationStyles1} />
            <Image fixed={images.deco2.fixed} css={decorationStyles2} />
          </>
        )}
      </Container>
    </Wrapper>
  )
}

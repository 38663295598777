import { css } from '@emotion/core'

import styled from '../../../../styles/theme'

import ExtensionImageBase from '../ExtensionImage'


export const ExtensionList = styled.div`
  ${({ theme }) => `
    counter-reset: extension;

    ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0 auto;
      max-width: 360px;
    }

    li {
      font-weight: ${theme.fonts.weights.light};
      line-height: 1.5;
      text-align: center;
      margin-top: ${theme.spacing.base * 6}px;
      margin-bottom: ${theme.spacing.base * 2.5}px;
    }

    li strong {
      font-weight: ${theme.fonts.weights.bold};
      color: ${theme.palette.primary.main};
    }

    li::before {
      counter-increment: extension;
      content: counter(extension);

      display: flex;
      width: 110px;
      height: 110px;
      line-height: 110px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: ${theme.spacing.base * 5}px;
      border-radius: 50%;
      padding: ${theme.spacing.base * 2}px;
      background-color: ${theme.palette.primary.main};
      background-clip: content-box;
      border: 4px solid ${theme.palette.secondary.light};
      text-align: center;
      justify-content: center;
      align-items: center;
      color: ${theme.palette.text.light};
      font-size: ${theme.fonts.sizes.xl};
      font-weight: ${theme.fonts.weights.bold};
    }

    @media (min-width: ${theme.breakpoints.md}px) {
      margin-top: ${theme.spacing.base * 5}px;

      ul {
        display: flex;
        max-width: initial;
      }

      li {
        flex: 0 0 50%;
        position: relative;
        padding-left: ${theme.spacing.base}px;
        padding-right: ${theme.spacing.base}px;
      }

      li::before {
        position: absolute;
        width: 95px;
        height: 95px;
        line-height: 95px;
        margin-bottom: 0;
        left: calc(50% - 47px);
      }

      .half-1 li {
        padding-bottom: 110px;
        margin-bottom: 0;
      }

      .half-1 li::before {
        bottom: 0;
      }

      .half-2 li {
        padding-top: 110px;
        margin-top: 0;
      }

      .half-2 li::before {
        top: 0;
      }
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      display: block;
      margin-top: ${theme.spacing.base * 10}px;

      .half-1-wrapper {
        position: relative;
      }

      ul {
        display: flex;
        justify-content: space-between;
        margin: 0;
        max-width: initial;
      }

      .half-1 li {
        padding-top: ${theme.spacing.base}px;
        padding-bottom: 0;
      }

      li {
        max-width: 35%;
        padding-bottom: 0;
        position: relative;
      }

      li::before {
        position: absolute;
        margin: 0;
        top: 0;
        left: initial;
        bottom: initial;
      }

      li:first-of-type::before {
        right: 0;
      }

      li:last-child::before {
        left: 0;
      }

      .half-2 {
        margin-top: ${theme.spacing.base * 12}px;
      }

      .half-2 li {
        padding-top: 0;
      }

      .half-1 li:first-of-type,
      .half-2 li:first-of-type {
        text-align: right;
        padding-right: ${theme.spacing.base * 13}px;
      }

      .half-1 li:last-child,
      .half-2 li:last-child {
        text-align: left;
        padding-left: ${theme.spacing.base * 13}px;
      }
    }

    @media (min-width: ${theme.breakpoints.xxl}px) {
      margin-left: -${theme.spacing.base * 6}px;
      margin-right: -${theme.spacing.base * 6}px;
      margin-top: ${theme.spacing.base * 15}px;

      li {
        max-width: 30%;
      }
    }
  `}
`

export const ExtensionImage = styled(ExtensionImageBase)`
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.lg}px) {
      position: absolute;
      top: calc(50% - ${theme.spacing.base * 5.5}px);
      left: 50%;
      margin-left: -150px;
    }

    @media (min-width: ${theme.breakpoints.xxl}px) {
      top: calc(50% - ${theme.spacing.base * 17.5}px);
      margin-left: -222.5px;
    }
  `}
`

export const decorationStyles1 = css`
  position: absolute !important;
  left: -60px;
  bottom: 750px;
`

export const decorationStyles2 = css`
  position: absolute !important;
  right: -50px;
  bottom: 800px;
`

export const decorationStyles3 = css`
  position: absolute !important;
  left: -50px;
  bottom: 60px;
`

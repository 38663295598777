export default [
  {
    key: 'enables',
    icon: 'ai-block-chef.png',
    list: [ 'item1', 'item2', 'item3', 'item4', 'item5' ],
  },
  {
    key: 'solutions',
    icon: 'ai-block-robot.png',
    list: [ 'item1', 'item2', 'item3', 'item4' ],
  },
  {
    key: 'advantages',
    icon: 'ai-block-manager.png',
    list: [ 'item1', 'item2' ],
  },
]

import React, { HTMLAttributes, useCallback, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Image from 'gatsby-image'

import Title, { IntroCentered } from '../../components/Title'
import Collapsible from '../../components/Collapsible'
import Container from '../../components/Container'
import Buttons, { Button, ToggleButton } from '../../components/Buttons'
import { useTheme } from '../../styles/theme'

import Extension from './ProductExtension'
import ProductArticle from './ProductArticle'
import Procurement from './Procurement'
import {
  Wrapper,
  TextField,
  CallToAction,
  decorationStyles2,
  decorationStyles3,
  marginedImgStyles,
} from './styles'
import productData from './productData'
import { ArticleDataProps } from './types'


export default (props: HTMLAttributes<HTMLElement>) => {
  const imagesData = useStaticQuery(graphql`
    query {
      start: imageSharp(fluid: { originalName: { eq: "1-startv-2.png" }}) {
        fluid(quality: 92, maxWidth: 1220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      overview: imageSharp(fluid: { originalName: { eq: "2-overviewv-2.png" }}) {
        fluid(quality: 92, maxWidth: 1220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      revenue: imageSharp(fluid: { originalName: { eq: "3-revenuev-2.png" }}) {
        fluid(quality: 92, maxWidth: 1220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      revenue2: imageSharp(fluid: { originalName: { eq: "4-revenuev-2.png" }}) {
        fluid(quality: 92, maxWidth: 1220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      robot: imageSharp(fixed: { originalName: { eq: "business-robot-cinema.png" }}) {
        fixed(quality: 92, width: 476, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco1: imageSharp(fixed: { originalName: { eq: "deco4.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco2: imageSharp(fixed: { originalName: { eq: "deco6.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco3: imageSharp(fixed: { originalName: { eq: "deco7.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  `)
  const { formatMessage } = useIntl()
  const [ extended, setExtended ] = useState(false)
  const handleClick = useCallback(() => {
    setExtended(!extended)
  }, [extended])
  const theme = useTheme()
  const decorative = useMediaQuery(
    `(min-width: ${theme.breakpoints.xxl + 120}px)`
  )
  const articleRenderer = useCallback(({ key, image }: ArticleDataProps) => (
    <ProductArticle
      key={key}
      imageData={imagesData[image].fluid}
      title={formatMessage({ id: `product.articles.${key}.title` })}
      text={formatMessage({ id: `product.articles.${key}.text` })}
    />
  ), [])
  const btnTextLearnMore = formatMessage({
    id: extended ? 'buttons.summary' : 'buttons.learn-more'
  })

  return (
    <Wrapper {...props}>
      <Container>
        <Title size="title" center>{formatMessage({ id: 'product.title' })}</Title>
        <IntroCentered>{formatMessage({ id: 'product.intro' })}</IntroCentered>
        {productData.map((article) => articleRenderer(article))}
        <Image fluid={imagesData.revenue2.fluid} css={marginedImgStyles} />
      </Container>
      <Procurement />
      <Container>
        <Collapsible open={extended} name="product-extension" parent="product">
          <Extension />
        </Collapsible>
        <TextField isLast="true">
          <CallToAction dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'product.outro' }) }} />
          <Buttons>
            <ToggleButton onClick={handleClick}>{btnTextLearnMore}</ToggleButton>
            <Button hash="contacts">{formatMessage({ id: 'buttons.contact'})}</Button>
          </Buttons>
        </TextField>
        {decorative && (
          <>
            <Image fixed={imagesData.deco2.fixed} css={decorationStyles2} />
            <Image fixed={imagesData.deco3.fixed} css={decorationStyles3} />
          </>
        )}
      </Container>
    </Wrapper>
  )
}

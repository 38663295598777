import { css } from '@emotion/core'

import ContainerBase, { Wrapper as WrapperBase } from '../../components/Container'
import ButtonsBase from '../../components/Buttons'
import styled from '../../styles/theme'
import bullet from '../../assets/images/bullet.svg'


export const Wrapper = styled(WrapperBase)`
  ${({ theme }) => `
    background: ${theme.palette.background.light};
  `}
`

export const Container = styled(ContainerBase)`
  position: relative;
`

export const Articles = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 969px;
  margin-left: auto;
  margin-right: auto;
`

export const Article = styled.article`
  ${({ theme }) => `
    background: ${theme.palette.background.light};
    border-radius: 4px;
    padding:
      ${theme.spacing.base * 1.5}px
      ${theme.spacing.base * 3}px
      ${theme.spacing.base * 1.5}px;
    font-weight: ${theme.fonts.weights.light};
    max-width: 302px;
    margin: ${theme.spacing.base * 6}px auto 0 auto;

    .ai-article-title {
      font-size: ${theme.fonts.sizes.subtitle};
    }

    ul {
      list-style-type: none;
      padding-left: 0;
      
    }

    li {
      padding-left: ${theme.spacing.base * 4.5}px;
      background-image: url(${bullet});
      background-repeat: no-repeat;
      background-position: 0 ${theme.spacing.base / 2}px;
      line-height: 1.175;
      padding-bottom: ${theme.spacing.base * 3}px;
    }

    strong {
      font-weight: ${theme.fonts.weights.bold};
      color: ${theme.palette.secondary.main};
    }
  `}
`

export const ArticleImageWrapper = styled.div`
  ${({ theme }) => `
    box-sizing: content-box;
    width: 104px;
    height: 104px;
    border-radius: 50%;
    border-width: ${theme.spacing.base * 0.75}px;
    border-color: ${theme.palette.secondary.light};
    padding: ${theme.spacing.base * 1.5}px;
    background-color: ${theme.palette.secondary.light};
    position: relative;
    background-clip: content-box;
    border-style: solid;
    margin-left: auto;
    margin-right: auto;
    
    & .gatsby-image-wrapper {
      position: absolute;
      left: 0;
      bottom: 0;
    }
  `}
`

export const articleIconStyles = {
  position: 'absolute',
  bottom: 0,
}

export const robotLearnMore = {
  position: 'absolute',
  top: '400px',
  left: '-80px',
}

export const robotContactUs = {
  position: 'absolute',
  top: '400px',
  right: '-80px',
}

export const decorationStyles1 = css`
  position: absolute !important;
  left: -50px;
  top: -163px;
`

export const decorationStyles2 = css`
  position: absolute !important;
  right: -62px;
  top: 180px;
`

export const Buttons = styled(ButtonsBase)`
  ${({ theme }) => `
    
  `}
`

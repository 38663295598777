import React from 'react'

import { ParagraphProps } from './types'


export default (({ type, children, ...rest }: ParagraphProps) => (
  <p {...rest}>
    {type ? (
      <a href={type === 'email' ? `mailto:${children}` : `tel:${(children as string).replace(/[^+\d]*/g, '')}`}>{children}</a>
    ) : children}
  </p>
))

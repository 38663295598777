import React, { FunctionComponent } from 'react'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { ThemeProvider } from 'emotion-theming'
import { injectIntl } from 'gatsby-plugin-intl'

import 'modern-normalize'

import Cookies from '../containers/Cookies'
import { FullScreen } from '../components/Container'
import { theme } from '../styles/theme'
import '../styles/normalize'


interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const IndexLayout: FunctionComponent = ({ children }) => (
  <ThemeProvider theme={theme}>
    <StaticQuery
      query={graphql`
        query IndexLayoutQuery {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={(data: StaticQueryProps) => (
        <>
          <Helmet
            title={data.site.siteMetadata.title}
            meta={[
              { name: 'description', content: data.site.siteMetadata.description },
              { name: 'keywords', content: data.site.siteMetadata.keywords }
            ]}
          />
          <FullScreen>
            {children}
          </FullScreen>
          <Cookies />
        </>
      )}
    />
  </ThemeProvider>
);

export default injectIntl(IndexLayout)

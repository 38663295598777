import React, { HTMLAttributes, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Image from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'

import Container from '../../components/Container'
import Buttons, { Button } from '../../components/Buttons'
import CallToAction from '../../components/CallToAction'
import { useTheme } from '../../styles/theme'
import { EdgeProps } from '../../types'

import Audience from '../Audience'

import {
  Wrapper,
  Intro,
  IntroText,
  Icons,
  Icon,
  IconText,
  stopSignStyles,
  decorationStyles1,
  decorationStyles2,
} from './styles'
import yourBenefitsData from './yourBenefitsData'


export default (props: HTMLAttributes<HTMLElement>) => {
  const imagesData = useStaticQuery(graphql`
    query {
      icons: allFile(filter: {childImageSharp: {fixed: {originalName: {regex: "/business/"}}}}) {
        edges {
          node {
            childImageSharp {
              fixed(quality: 92, width: 180, fit: CONTAIN, background: "transparent") {
                originalName
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
      stopSign: imageSharp(fluid: {originalName: {eq: "sign-stop-wasting-food.png"}}) {
        fluid(quality: 92, maxWidth: 320, fit: CONTAIN) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      audience: allFile(filter: {childImageSharp: {fixed: {originalName: {regex: "/audient/"}}}}) {
        edges {
          node {
            childImageSharp {
              fixed(quality: 92, width: 212) {
                originalName
                height
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
      deco1: imageSharp(fixed: { originalName: { eq: "deco3.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco2: imageSharp(fixed: { originalName: { eq: "deco5.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }

    }
  `)

  const { formatMessage } = useIntl()
  const iconRenderer = useCallback((item) => {
    const image = imagesData.icons.edges.find(({ node }: EdgeProps) => (
      node.childImageSharp.fixed.originalName === item.icon
    ))

    if (image) {
      return (
        <Icon key={item.key} important={item.important}>
          <Image fixed={image.node.childImageSharp.fixed} className="picture" />
          <IconText dangerouslySetInnerHTML={{__html: formatMessage({ id: `your-benefits.icons.${item.key}` }) }} />
        </Icon>
      )
    } else {
      console.error(Error(`Missing "${item.icon}" image in Business Model block`))
    }
  }, [])
  const theme = useTheme()
  const decorative = useMediaQuery(`(min-width: ${theme.breakpoints.xxl+120}px)`)

  return (
    <Wrapper {...props}>
      <Container>
        <Intro>
          <Image fluid={imagesData.stopSign.fluid} css={stopSignStyles} />
          <IntroText dangerouslySetInnerHTML={{__html: formatMessage({ id: 'your-benefits.intro' }) }} />
        </Intro>
        <Audience />
        <Icons>
          {yourBenefitsData.map(item => iconRenderer(item))}
        </Icons>
        <CallToAction dangerouslySetInnerHTML={{__html: formatMessage({ id: 'your-benefits.outro' }) }} />
        <Buttons>
          <Button hash="contacts">{formatMessage({ id: 'buttons.contact' })}</Button>
        </Buttons>
        {decorative && <Image fixed={imagesData.deco1.fixed} css={decorationStyles1} />}
        {decorative && <Image fixed={imagesData.deco2.fixed} css={decorationStyles2} />}
      </Container>
    </Wrapper>
  )
}

import React from 'react'
import Image from 'gatsby-image'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import {
  ExtensionImageWrapper,
  extensionPictureMediaQuery,
  extensionPictureStyles,
} from './styles'
import { ExtensionImageProps } from './types'


export default ({ big, small, ...rest }: ExtensionImageProps) => {
  const takeBig = useMediaQuery(extensionPictureMediaQuery)
  
  return (
    <ExtensionImageWrapper {...rest}>
      <Image
        fixed={takeBig ? big : small}
        style={extensionPictureStyles}
      />
    </ExtensionImageWrapper>
  )
}

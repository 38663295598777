import styled from '../../../../styles/theme'
import TitleBase from '../../../../components/Title'

import ExtensionImageBase from '../ExtensionImage'

export const AdvantagesWrapper = styled.div`
  ${({ theme }) => `
    ul {
      padding: 0;
      list-style-type: none;
      font-weight: ${theme.fonts.weights.light};
      text-align: center;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      
      strong {
        color: ${theme.palette.primary.main};
        font-weight: ${theme.fonts.weights.bold};
      }
      
      li {
        position: relative;
        line-height: 1.5;
        padding: ${theme.spacing.base * 5}px 0;
        border-bottom: 2px solid ${theme.palette.secondary.light};
      }
      li::before,
      li::after {
        content: '';
        display: inline-block;
        position: absolute;
        bottom: -4px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        border: 2px solid ${theme.palette.secondary.light};
      }
      li::before {
        left: -5px;
      }
      li::after {
        right: -5px;
      }
    }

    @media (max-width: ${theme.breakpoints.lg - 1}px) {
      ul {
        li:last-child {
          border-bottom: none;
        }
        li:last-child::before,
        li:last-child::after {
          display: none;
        }
      }
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      padding-top: ${theme.spacing.base * 5}px;
      padding-bottom: ${theme.spacing.base * 10}px;

      .list-wrapper {
        display: flex;
      }

      ul {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      li {
        flex: 0 0 33.333%;
      }

      .left {
        text-align: left;
      }

      .left li {
        padding-right: ${theme.spacing.base * 3}px;
      }

      .right {
        text-align: right;
      }

      .right li {
        padding-left: ${theme.spacing.base * 3}px;
      }
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      ul {
        .left li {
          padding-right: ${theme.spacing.base * 5}px;
        }

        .right li {
          padding-left: ${theme.spacing.base * 5}px;
        }
      }
    }

    @media (min-width: ${theme.breakpoints.xxl}px) {
      ul {
        max-width: 250px;

        li {
          padding-top: ${theme.spacing.base * 8}px;
          padding-bottom: ${theme.spacing.base * 6}px;
        }

        .left li {
          padding-right: ${theme.spacing.base * 2}px;
        }

        .right li {
          padding-left: ${theme.spacing.base * 2}px;
        }
      }

      .left {
        margin-right: ${theme.spacing.base * 2.5}px;
      }

      .right {
        margin-left: ${theme.spacing.base * 2.5}px;
      }
    }
  `}
`

export const Title = styled(TitleBase)`
  ${({ theme }) => `
    font-size: ${theme.fonts.sizes.subtitle};
  `}
`

export const ExtensionImage = styled(ExtensionImageBase)`
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.lg}px) {
      align-self: flex-end;
      margin-bottom: 4%;
    }

    @media (min-width: ${theme.breakpoints.xl}px) {
      margin-bottom: 0;
    }
  `}
`

import React from 'react'

import { ContactLinkProps } from './types'


export default (({ type, children, ...rest }: ContactLinkProps) => (
  <>
    {type === 'email' ? 'e-mail: ' : 'phone: '}
    <a
      href={
        type === 'email'
        ?
        `mailto:${children}`
        :
        `tel:${(children as string).replace(/[^+\d]*/g, '')}`}
        {...rest}
      >
      {children}
    </a>
  </>
))

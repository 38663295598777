import styled from '../../styles/theme'


export const MenuLogoWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.background.light};
  height: 100%;
  display: inline-flex;
  align-items: center;
  
  &::after {
    content: '';
    display: inline-block;
    width: ${({ theme }) => theme.heights.header}px;
    height: ${({ theme }) => theme.heights.header}px;
    background: ${({ theme }) => theme.palette.background.light};
    transform: rotate(-45deg) translate(${({ theme }) => theme.heights.header / 2}px, ${({ theme }) => theme.heights.header / 2}px);
    border-radius: 4px;
    margin-left: -${({ theme }) => theme.heights.header}px;
    z-index: 0;
  }
  
  a {
    z-index: 1;
  }
  
  svg {
    display: block;
  }
`;

export const FooterLogoWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const BlogLogoWrapper = styled.div`
  display: flex;
  align-items: baseline;
`;

export const BlogLogoTitle = styled.div`
  ${({ theme }) => `
    font-size: 43px;
    font-weight: ${theme.fonts.weights.heavy};
    margin-left: ${theme.spacing.base * 3}px;
    color: ${theme.palette.text.light};

    @media (min-width: ${theme.breakpoints.lg}px) {
      font-size: 67px;
    }
  `}
`

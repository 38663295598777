import React from 'react'
import Image from 'gatsby-image'

import Title, { IntroCentered } from '../../components/Title'

import { imgStyles, TextField, ProductArticleWrapper } from './styles'
import { ProductArticleProps } from './types'


export default ({ imageData, title, text }: ProductArticleProps) => (
  <ProductArticleWrapper>
    <Image fluid={imageData} css={imgStyles} />
    <TextField>
      <Title size="regular" component="h3">{title}</Title>
      <IntroCentered>{text}</IntroCentered>
    </TextField>
  </ProductArticleWrapper>
)

import React from 'react'
import Image from 'gatsby-image'

import { AudientProps } from './types'
import { AudientWrapper, Title, IntroCentered } from './styles'


export default ({ fixedImage, title, text, ...props }: AudientProps) => (
  <AudientWrapper {...props} style={{ marginTop: `${260 - fixedImage.height}px`}}>
    {fixedImage && <Image fixed={fixedImage} className="picture" />}
    <Title size="regular" component="h3">{title}</Title>
    <IntroCentered>{text}</IntroCentered>
  </AudientWrapper>
)

import Image from 'gatsby-image';

import styled from '../../../styles/theme';
import TitleBase from '../../../components/Title';
import Collapsible from '../../../components/Collapsible';

import { CircleBorderProps, ExtendedProps, DescriptionProps } from './types';
import { css } from '@emotion/core';

export const Mate = styled.article<ExtendedProps>`
  ${({ theme, extended }) => css`
    padding-bottom: ${theme.spacing.base * 5}px;
    padding-left: ${theme.spacing.base * 2}px;
    padding-right: ${theme.spacing.base * 2}px;

    @media (min-width: ${theme.breakpoints.md}px) {
      flex-basis: 50%;
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      flex-basis: ${extended ? '960px' : '33.333%'};

      ${extended
        ? `
        display: flex;
        align-items: center;
      `
        : ''}
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      ${!extended && 'max-width: 312px'};
    }

    @media (min-width: ${theme.breakpoints.xl}px) {
      flex-basis: ${extended ? '960px' : '25%'};
      ${extended ? '' : 'max-width: 25%;'}
    }
  `}
`;

export const CircleBorder = styled.div<CircleBorderProps>`
  ${({ theme }) => css`
    border-radius: 50%;
    border-style: solid;
    border-width: ${theme.spacing.base}px;
    border-color: ${theme.palette.background.light};
    padding: ${theme.spacing.base}px;
    height: 190px;
    width: 190px;
    margin-left: auto;
    margin-right: auto;

    .even & {
      order: 2;
    }
  `}
`;

export const RoundPhoto = styled(Image)`
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
  overflow: hidden;
`;

export const Description = styled(Collapsible)<DescriptionProps>`
  ${({ theme, partner, textAlignToLeft }) => css`
    font-weight: ${theme.fonts.weights.light};
    font-size: ${theme.fonts.sizes.sm};
    line-height: 1.5;

    @media (max-width: ${theme.breakpoints.lg - 1}px) {
      margin: 0px;
      max-width: ${partner ? '300px' : ''};
    }

    @media (max-width: ${theme.breakpoints.xl - 150}px) {
      margin-top: ${theme.spacing.base}px;
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      .odd & {
        padding-right: 110px;
      }
      .even & {
        padding-left: 110px;
      }
      min-width: 700px;
    }

    ${textAlignToLeft &&
    css`
      text-align: left;
      p {
        margin-left: 1rem;
      }
    `}
  `}
`;

export const Details = styled.div<ExtendedProps>`
  ${({ theme, extended }) => css`
    text-align: center;
    padding-top: ${theme.spacing.base * 3}px;
    padding-bottom: ${theme.spacing.base * 3}px;

    ${extended
      ? css`
          @media (min-width: ${theme.breakpoints.lg}px) {
            .odd & {
              text-align: left;
              padding-left: ${theme.spacing.base * 3}px;
            }

            .even & {
              text-align: right;
              padding-right: ${theme.spacing.base * 3}px;
            }
          }
        `
      : ''}
  `}
`;

export const PositionWrapper = styled.div<ExtendedProps>`
  ${({ theme, extended }) => css`
    ${extended
      ? css`
          @media (min-width: ${theme.breakpoints.lg}px) {
            display: flex;
            align-items: center;

            .odd & > p:first-child {
              margin-right: ${theme.spacing.base * 3}px;
            }

            .even & {
              justify-content: flex-start;
              flex-direction: row-reverse;
            }
            .even & > p:first-child {
              margin-left: ${theme.spacing.base * 3}px;
            }
          }
        `
      : ''}
  `}
`;

export const Position = styled(TitleBase)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.base}px;
    margin-bottom: ${theme.spacing.base * 0.5}px;
    max-width: initial;
    margin-left: initial;
    margin-right: initial;
    text-transform: uppercase;
  `}
`;

export const Name = styled(TitleBase)`
  ${({ theme }) => css`
    margin-top: ${theme.spacing.base}px;
    margin-bottom: ${theme.spacing.base}px;
    max-width: initial;
    margin-left: initial;
    margin-right: initial;
    text-align: inherit;
    text-transform: uppercase;
  `}
`;

export const About = styled.p`
  ${({ theme }) => css`
    font-weight: ${theme.fonts.weights.light};
    line-height: 1.5;
  `}
`;

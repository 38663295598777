export default [
  {
    key: 'certainty',
    icon: 'business-target.png',
  },
  {
    key: 'hr',
    icon: 'business-personel.png',
  },
  {
    key: 'waste',
    icon: 'business-no-waste.png',
  },
  {
    key: 'efficiency',
    icon: 'business-kitchen-efficiency.png',
  },
  {
    key: 'savings',
    icon: 'business-savings.png',
    important: true,
  },
  {
    key: 'procurement',
    icon: 'business-procurement.png',
  },
  {
    key: 'trends',
    icon: 'business-trends.png',
  },
  {
    key: 'ai',
    icon: 'business-menu-planning.png',
  },
  {
    key: 'customer',
    icon: 'business-happy-customers.png',
  },
]

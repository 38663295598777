import styled from '../../styles/theme'


export const CallToActionWrapper = styled.div`
  ${({ theme }) => `
    max-width: 290px;
    margin: ${theme.spacing.base * 5}px auto 0;
    text-align: center;
    font-weight: 300;
    line-height: 1.65;
    
    p {
      margin-top: 0;
      margin-bottom: 0;
    }
    
    strong {
      font-weight: ${theme.fonts.weights.bold};
    }
    
    @media (min-width: ${theme.breakpoints.lg}px) {
      margin-top: ${theme.spacing.base * 10}px;
    }
  `}
`

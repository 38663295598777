import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import { Link as GatsbyLink } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { useLocation } from '@reach/router'

import { useTheme } from '../../styles/theme'

import { MenuLinkProps, ActualLinkProps } from './types'


const rootPaths = [ '/', '/en', '/de' ]

export default ({ hash, menu, ...rest }: MenuLinkProps) => {
  const theme = useTheme()
  const { formatMessage, locale, defaultLocale } = useIntl()
  const location = useLocation()
  const href = locale !== defaultLocale ? `/${locale }/#${hash}` : `/#${hash}`
  const Link = rootPaths.includes(location.pathname) ? (
    (props: ActualLinkProps) => (
      <ScrollLink 
        to={hash}
        href={href}
        offset={-theme.heights.header}
        hashSpy
        smooth
        {...props}
      />
    )
  ) : (
    (props: ActualLinkProps) => <GatsbyLink to={href} {...props} />
  )

  return (
    <Link {...rest}>
      {formatMessage({ id: `menu.${menu}` })}
    </Link>
  )
}

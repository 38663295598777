import { HTMLAttributes } from 'react'
import styled from '../../styles/theme'

import Container from '../../components/Container'


export const FooterContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.spacing.base*4}px 0px;
`

export const FooterWrapper = styled.div`
  background: ${({ theme }) => theme.palette.background.dark};
`

export const FooterColumn = styled.div`
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;

  @media(max-width: ${({ theme }) => theme.breakpoints.lg - 1}px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`

export const Contact = styled.div`
  display:flex;
  flex-direction:row;
  align-items:start;
  justify-content: space-around;
`

export const FooterText = styled.p<FooterTextProps>`
  ${({ theme, light, space, footerSpacing }) => `
    font-size: ${theme.fonts.sizes.xs};
    color: ${theme.palette.text.light};
    font-weight: ${theme.fonts.weights[light ? 'light' : 'medium']};
    transition: ${theme.transitions[0]};

    ${space ? `margin-top: 2.5rem;` : ''}
    ${footerSpacing ? `letter-spacing: 1px;` : ''}

    a {
      color: inherit;
      text-decoration: none;
    }
  `}
`

export const MobileBlock = styled.div`
  margin: ${({ theme }) => theme.spacing.base}px;
`

export interface FooterTextProps extends HTMLAttributes<HTMLElement> {
  space?: boolean
  light?: boolean
  footerSpacing?: boolean
}

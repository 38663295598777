import { css } from '@emotion/core'

import styled from '../../styles/theme'
import { Wrapper as WrapperBase } from '../../components/Container'
import ButtonsBase from '../../components/Buttons'

import { StaffProps } from './types'


export const Wrapper = styled(WrapperBase)`
  ${({ theme }) => `
    background: ${theme.palette.background.plain};
  `}
`

export const Staff = styled.div<StaffProps>`
  ${({ theme }) => `
    margin-left: -${theme.spacing.base * 2}px;
    margin-right: -${theme.spacing.base * 2}px;
    padding-top: ${theme.spacing.base * 6}px;

    @media (min-width: ${theme.breakpoints.md}px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  `}
`

export const Partners = styled.div`
  ${({ theme }) => `
    padding-top: ${theme.spacing.base * 3}px;
    margin-left: -${theme.spacing.base * 2}px;
    margin-right: -${theme.spacing.base * 2}px;

    @media (min-width: ${theme.breakpoints.md}px) {
      padding-top: ${theme.spacing.base * 6}px;
      display: flex;
      justify-content: center;
    }
  `}
`

export const decorationStyles1 = (extended: boolean) => css`
  position: absolute !important;
  left: -190px;
  transition: top 400ms ease-out;
  top: ${!extended ? '-157px' : '-1600px'};
`

export const decorationStyles2 = css`
  position: absolute !important;
  right: -190px;
  bottom: 507px;
`

export const decorationStyles3 = (extended: boolean) => css`
  position: absolute !important;
  left: -187px;
  transition: top 400ms ease-out;
  top: ${!extended ? '30px' : '430px'};
`

export const decorationStyles4 = css`
  position: absolute !important;
  right: -190px;
  bottom: -145px;
`

export const Buttons = styled(ButtonsBase)`
 & {
   padding-top: 0;
 }
`

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import { CookiesDialog, Message, Footer, Button } from './styles'
import { CookiesDialogProps } from './types'


export default ({ onStop, onAccept }: CookiesDialogProps) => {
  const { formatMessage } = useIntl()
  
  return (
    <CookiesDialog>
      <Message dangerouslySetInnerHTML={{ __html: formatMessage({ id: 'cookies.message' }) }}/>
      <Footer>
        <Button onClick={onStop}>{formatMessage({ id: 'cookies.stop-button' })}</Button>
        <Button onClick={onAccept}>{formatMessage({ id: 'cookies.accept-button' })}</Button>
      </Footer>
    </CookiesDialog>
  )
}

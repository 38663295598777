import React, { HTMLAttributes, useCallback, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import { useIntl } from 'gatsby-plugin-intl'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import Title, { IntroCentered } from '../../components/Title'
import { Button, ToggleButton } from '../../components/Buttons'
import Collapsible from '../../components/Collapsible'
import { EdgeProps } from '../../types'
import { useTheme } from '../../styles/theme'

import Article from './Article'
import Articles from './Articles'
import Extension from './components/Extension'
import Advantages from './components/Advantages'
import artificialIntelligenceData from './artificialIntelligenceData'
import {
  Wrapper,
  Container,
  decorationStyles1,
  decorationStyles2,
  Buttons,
} from './styles'


export default (props: HTMLAttributes<HTMLElement>) => {
  const imagesData = useStaticQuery(graphql`
    query {
      icons: allFile(
        filter: {
          childImageSharp: { fixed: { originalName: { regex: "/ai-block/" } } }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fixed(quality: 92, width: 128) {
                originalName
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
      robot: imageSharp(
        fixed: { originalName: { eq: "business-robot-cinema.png" } }
      ) {
        fixed(quality: 92, width: 476, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco1: imageSharp(fixed: { originalName: { eq: "deco1.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco2: imageSharp(fixed: { originalName: { eq: "deco5.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  `)
  const [ extended, setExtended ] = useState(false)
  const { formatMessage } = useIntl()
  const handleClick = useCallback(() => {
    setExtended(!extended)
  }, [extended])
  const btnTextLearnMore = formatMessage({
    id: extended ? 'buttons.summary' : 'buttons.learn-more'
  })
  const theme = useTheme()
  const decorative = useMediaQuery(
    `(min-width: ${theme.breakpoints.xxl + 120}px)`
  )

  return (
    <Wrapper {...props}>
      <Container>
        <Title center>{formatMessage({ id: 'ai.title' })}</Title>
        <IntroCentered>{formatMessage({ id: 'ai.intro' })}</IntroCentered>
        <Articles>
          {artificialIntelligenceData.map(({ key, icon, list }) => {
            const image = imagesData.icons.edges.find(({ node }: EdgeProps) => (
              node.childImageSharp.fixed.originalName === icon
            ))

            return (
              <Article
                key={key}
                subject={key}
                fixedImage={image.node.childImageSharp.fixed}
                list={list}
              />
            );
          })}
        </Articles>
        <Collapsible
          open={extended}
          name="ai-extension"
          parent="artificial-intelligence"
        >
          <Extension />
          <Advantages />
        </Collapsible>
        <Buttons>
          <ToggleButton onClick={handleClick}>{btnTextLearnMore}</ToggleButton>
          <Button hash="contacts">{formatMessage({ id: 'buttons.contact' })}</Button>
        </Buttons>
        {decorative && (
          <>
            <Image fixed={imagesData.deco1.fixed} css={decorationStyles1} />
            <Image fixed={imagesData.deco2.fixed} css={decorationStyles2} />
          </>
        )}
      </Container>
    </Wrapper>
  )
}

import React, { useCallback, HTMLProps } from 'react'
import { Link } from 'gatsby-plugin-intl'
import { animateScroll } from 'react-scroll'

import Logo from './Logo'

import { MenuLogoWrapper } from './styles'


export default (props: HTMLProps<HTMLDivElement>) => {
  const clickHandler = useCallback((e) => {
    animateScroll.scrollToTop()
  }, [])
  
  return (
    <MenuLogoWrapper {...props}>
      <Link to="/" onClick={clickHandler}>
        <Logo />
      </Link>
    </MenuLogoWrapper>
  )
}

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'

import Title from '../../components/Title'

import ArticleImage from './ArticleImage'
import { ArticleProps } from './types'
import { Article } from './styles'


export default ({ subject, fixedImage, list }: ArticleProps) => {
  const { formatMessage } = useIntl()

  return (
    <Article>
      <ArticleImage fixedImage={fixedImage} />
      <Title
        center
        component="h3"
        dangerouslySetInnerHTML={{ __html: formatMessage({ id: `ai.articles.${subject}.title` }) }}
        className="ai-article-title"
      />
      <ul>
        {list.map(key => (
          <li
            key={key}
            dangerouslySetInnerHTML={{ __html: formatMessage({ id: `ai.articles.${subject}.list.${key}` }) }} />
        ))}
      </ul>
    </Article>
  )
}

import styled from '../../styles/theme'


export const Container = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fonts.fontFamily};
    max-width: 1250px;
    padding-left: 7.5vw;
    padding-right: 7.5vw;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @media (min-width: ${theme.breakpoints.lg}px) {
      padding-left: ${theme.spacing.base * 2}px;
      padding-right: ${theme.spacing.base * 2}px;
    }
  `}
`

export const Wrapper = styled.div`
  ${({ theme }) => `
    padding-top: ${theme.spacing.base * 2.5}px;

    @media (min-width: ${theme.breakpoints.lg}px) {
      padding-top: ${theme.spacing.base * 3}px;
    }

    @media (min-width: ${theme.breakpoints.xl}px) {
      padding-top: ${theme.spacing.base * 7}px;
    }
  `}
`

export const FullScreen = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`

import styled, { CreateStyled } from '@emotion/styled'
import { useTheme as useThemeOriginal } from 'emotion-theming'

import {
  breakpoints,
  heights,
  zIndex,
  buttonsDimensions,
  gradients,
} from './variables'


export type ThemeType = {
  palette: {
    primary: {
      main: string
      grey: string
      light: string
    }
    secondary: {
      main: string
      light: string
      grey: string
    }
    background: {
      dark: string
      light: string
      plain: string
    }
    text: {
      main: string
      dark: string
      light: string
    }
  }
  fonts: {
    fontFamily: string,
    sizes: {
      regular: string
      sm: string
      xs: string
      xl: string
      title: string
      subtitle: string
    }
    weights: {
      light: number
      regular: number
      medium: number
      bold: number
      heavy: number
    }
  }
  shadows: string[]
  transitions: string[]
  breakpoints: typeof breakpoints
  heights: typeof heights
  zIndex: typeof zIndex
  spacing: {
    base: number
  }
  buttonsDimensions: typeof buttonsDimensions
  gradients: typeof gradients
}

export const theme: ThemeType = {
  palette: {
    primary: {
      main: '#0d807e',
      light: '#1bafa7',
      grey: '#8c8c8c',
    },
    secondary: {
      main: '#b30b00',
      light: '#a6dedd',
      grey: '#bababa',
    },
    background: {
      dark: '#212121',
      light: '#ffffff',
      plain: '#cbf2f2',
    },
    text: {
      main: '#000000',
      dark: '#000000',
      light: '#ffffff',
    },
  },
  shadows: [
    '7px 7px 13px rgba(9, 38, 48, 0.13)',
    '-11px 10px 29px rgba(0, 0, 0, 0.09)',
    '0 0 2px rgba(13, 128, 126, 0.5)',
    '0 0 2px rgba(179, 11, 0, 0.5)',
  ],
  transitions: [
    'color 400ms ease-out',
    'transform 400ms ease-out',
    'opacity 400ms ease-out',
    'background 400ms ease-out',
    'border-color 400ms ease-out',
  ],
  fonts: {
    fontFamily: "'Roboto', sans-serif",
    sizes: {
      regular: '17px',
      sm: '15px',
      xs: '12px',
      xl: '39px',
      title: '33px',
      subtitle: '18px',
    },
    weights: {
      light: 300,
      regular: 400,
      medium: 500,
      bold: 700,
      heavy: 900,
    }
  },
  breakpoints,
  heights,
  zIndex,
  spacing: {
    base: 8,
  },
  buttonsDimensions,
  gradients,
}

export const useTheme = () => useThemeOriginal<ThemeType>()

export default styled as CreateStyled<ThemeType>

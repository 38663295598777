import styled from '../../../../styles/theme'
import ButtonsBase, { Button as ButtonBase } from '../../../../components/Buttons'

import { Paragraph } from '../../styles'


export const Wrapper = styled.div`
  ${({ theme }) => `
    max-width: 457px;
    margin-left: auto;
    margin-right: auto;
    border: 4px solid ${theme.palette.background.plain};
    box-shadow: ${theme.shadows[1]};

    @media (min-width: ${theme.breakpoints.md}px) {
      flex: 0 0 60%;
      margin-right: 0;
      margin-left: auto;
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      flex: 0 0 50%;
      margin-left: 0;
      margin-right: auto;
      margin-top: ${theme.spacing.base * 5}px;
    }
  `}
`

export const Form = styled.form`
  ${({ theme }) => `
    background: ${theme.palette.background.light};
    border-radius: 4px;
    padding: ${theme.spacing.base * 5}px ${theme.spacing.base * 3}px;
    text-align: left;
    position: relative;

    @media (min-width: ${theme.breakpoints.lg}px) {
      padding: ${theme.spacing.base * 3}px ${theme.spacing.base * 5}px;
    }
  `}
`

export const Button = styled(ButtonBase)`
  ${({ theme }) => `
    min-width: ${theme.spacing.base * 22}px;
  `}
`

export const Buttons = styled(ButtonsBase)`
  text-align: center;
  padding-bottom: 0;
`

export const Note = styled(Paragraph)`
  ${({ theme, size }) => `
    color: ${theme.palette.primary.grey};

    ${size ? `
      font-size: ${theme.fonts.sizes[size]};
    ` : ''}
  `}
`

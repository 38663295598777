import styled from '../../../styles/theme'


export const MobileWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`

export const DesktopWrapper = styled.div`
  max-width: 1012px;
  margin-left: auto;
  margin-right: auto;
`

export const Spacer = styled.div`
  width: 100%;
  height: 100px;
`

export const LogoWrapper = styled.div`
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 40px;
`

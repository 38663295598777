import React from 'react'

import styled from '../../../../styles/theme'

import { FieldsetProps } from './types'


export const Fieldset = styled.div<FieldsetProps>`
  ${({ theme }) => `
    font-size: ${theme.fonts.sizes.sm};

    label {
      display: block;
      line-height: ${4/3};
      margin-top: ${theme.spacing.base * 2}px;
      font-weight: ${theme.fonts.weights.light};
      transition: color 400ms ease-out, text-shadow 400ms ease-out;
    }

    textarea,
    input {
      display: block;
      width: 100%;
      margin-top: ${theme.spacing.base * 2}px;
      border-radius: 4px;
      border: 1px solid ${theme.palette.secondary.grey};
      padding: ${theme.spacing.base * 1.25}px;
      transition: border-color 400ms ease-out, box-shadow 400ms ease-out;
    }

    textarea::placeholder,
    input::placeholder {
      color: ${theme.palette.primary.grey};
      opacity: 1;
      font-weight: ${theme.fonts.weights.light};
      font-style: oblique;
    }

    textarea:focus,
    input:focus {
      outline: none;
      border-color: ${theme.palette.primary.main};
      box-shadow: ${theme.shadows[0]}, ${theme.shadows[2]};
    }

    textarea {
      resize: vertical;
      border-bottom-right-radius: 0;
    }

    &.error {
      label {
        color: ${theme.palette.secondary.main};
        text-shadow: 0 0 1px;
      }

      textarea,
      input {
        border-color: ${theme.palette.secondary.main};
      }

      textarea:focus,
      input:focus {
        box-shadow: ${theme.shadows[0]}, ${theme.shadows[3]};
      }
    }

    @media (min-width: ${theme.breakpoints.sm}px) and (max-width: ${theme.breakpoints.md - 1}px) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      input {
        flex: 0 1 60%;
      }
    }
    
    @media (min-width: ${theme.breakpoints.lg}px) {
      display: flex;
      justify-content: space-between;
      align-items: center;

      input {
        flex: 0 1 60%;
      }
    }
  `}
`

export const Star = styled(props => <span {...props}>*</span>)`
  ${({ theme }) => `
    color: ${theme.palette.secondary.main};
    font-weight: ${theme.fonts.weights.medium};
  `}
`

import { css } from "@emotion/core";

import styled from "../../styles/theme";
import ContainerBase, {
  Wrapper as WrapperBase,
} from "../../components/Container";
import ButtonsBase from "../../components/Buttons";

export const Wrapper = styled(WrapperBase)`
  background: ${({ theme }) => theme.gradients[1]};
`;

export const Container = styled(ContainerBase)`
  text-align: center;
`;

export const Title = styled.h1`
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 1px;
  margin-bottom: 0;
  color: ${({ theme }) => theme.palette.text.light};
`;

export const Subtitle = styled.h2`
  font-size: 31px;
  font-weight: 400;
  letter-spacing: 1px;
  margin-top: 0.075em;
  margin-bottom: 1.5em;
  color: ${({ theme }) => theme.palette.text.light};
`;

export const HeroImages = styled.div`
  ${({ theme }) => `
    margin-left: -${theme.spacing.base * 2}px;
    margin-right: -${theme.spacing.base * 2}px;

    @media (min-width: ${theme.breakpoints.md}px) and (max-width: ${theme.breakpoints.lg - 1}px) {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      min-height: 405px;

      display: flex;
      align-items: flex-end;
    }

    @media (min-width: ${theme.breakpoints.xl}px) {
      min-height: 512px;
    }
  `}
`;

export const robotStyles = css`
  max-width: 100%;
`;

export const desktopBannerStyles = css`
  height: 100%;
  width: 100%;
`;

export const TabletImageWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  flex: 1 1 50%;
  max-width: 50%;

  .gatsby-image-wrapper {
    max-width: 100%;
  }
`;

export const tabletImageStyles = {
  maxWidth: "100%",
  height: "auto",
};

export const benefitsMobileStyles = robotStyles;

export const Buttons = styled(ButtonsBase)`
  width: 100%;
`;

export const decorationStyles = css`
position:absolute !important;
right:-150px;
bottom:-100px;
`;

import styled from '../../styles/theme'
import Container from '../../components/Container'


export const MenuContainer = styled(Container)`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: ${theme.spacing.base * 2}px;
    padding-right: ${theme.spacing.base * 2}px;
    height: ${theme.heights.header}px;
  `}
`

export const MenuWrapper = styled.header`
  background: linear-gradient(to right, #fff 0, #fff 50%, #212121 50%, #212121 100%);
  overflow: hidden;
  position: sticky;
  top: 0;
  z-index: ${({ theme }) => theme.zIndex.navbar};
`

export const MenuBar = styled.nav`
  ${({ theme }) => `
    background-color: ${theme.palette.background.dark};
    height: 100%;
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: 24px;
    padding-left: ${theme.heights.header}px;
    overflow: hidden;
  `}
`

export const MenuItem = styled.span`
  ${({ theme }) => `
    display: inline-block;
    padding-left: ${theme.spacing.base * 0.125}px;
    padding-right: ${theme.spacing.base * 0.125}px;

    & a {
      color: ${theme.palette.text.light};
      text-decoration: none;
      font-size: 0.75rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 500;
      transition: ${theme.transitions[0]};
    }

    & a:hover {
      color: #7d7d7d;
    }

    footer & {
      padding-top:0.20em;
      padding-bottom:0.20em;
    }

    @media(max-width: ${theme.breakpoints.lg - 1}px) {
      & {
        display: block;
        padding-top: 0.65em;
        padding-bottom: 0.65em;
      }
    }
  `}
`

export const ToggleButton = styled.button`
  width: 48px;
  background: none;
  padding: 0;
  margin: 0 0 0 auto;
  border: 0;
  outline: none;
  
  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    background: transparent;
  }
`

export const DrawerContent = styled.div`
  ${({ theme }) => `
    background-color: ${theme.palette.background.dark};
    padding-top: ${theme.heights.header}px;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100vw;
    width: 360px;
    height: 100vh;
  `};
`

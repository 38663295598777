import { css } from '@emotion/core'

import styled from '../../styles/theme'
import { Wrapper as WrapperBase } from '../../components/Container'
import { Intro as IntroBase } from '../../components/Title'


export const Wrapper = styled(WrapperBase)`
  ${({ theme }) => `
    background: ${theme.palette.background.light};
  `}
`

export const Intro = styled.div`
  ${({ theme }) => `
    @media(max-width: ${theme.breakpoints.lg - 1}px) {
      padding-top: 24px;
    }

    @media(min-width: ${theme.breakpoints.lg}px) {
      display: flex;
      align-items: center;
    }
  `}
`;

export const IntroText = styled(IntroBase)`
  ${({ theme }) => `
    padding-top: ${theme.spacing.base * 4}px;

    & ::marker {
      color: ${theme.palette.secondary.main};
    }

    @media(min-width: ${theme.breakpoints.lg}px) {
      padding-top: ${theme.spacing.base * 7}px;
    }
  `}
`

export const Icons = styled.div`
  ${({ theme }) => `
    text-align: center;
    padding-left: ${theme.spacing.base * 4}px;
    padding-right: ${theme.spacing.base * 4}px;
    max-width: ${theme.breakpoints.lg}px;
    margin-left: auto;
    margin-right: auto;

    @media (min-width: ${theme.breakpoints.sm}px) {
      padding-left: ${theme.spacing.base}px;
      padding-right: ${theme.spacing.base}px;

      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      margin-top: ${theme.spacing.base * 4}px;
    }
    
    @media (min-width: ${theme.breakpoints.xl}px) {
      padding-left: 0;
      padding-right: 0;
    }
  `}
`

export const Icon = styled.div<{important?: boolean}>`
  ${({ theme, important }) => `
    border: ${theme.spacing.base * 0.5}px solid ${important ? theme.palette.secondary.main : theme.palette.background.plain};
    padding: ${theme.spacing.base * 3}px ${theme.spacing.base * 4}px ${theme.spacing.base * 2}px;
    margin: ${theme.spacing.base * 6}px auto;
    max-width: 260px;
    box-shadow: ${theme.shadows[1]};
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: ${theme.breakpoints.sm}px) {
      width: 100%;
      max-width: calc(50% - ${theme.spacing.base * 2}px);
      margin: ${theme.spacing.base * 2}px 0;

      @media (max-width: ${theme.breakpoints.md - 1}px) {
        :last-child {
          margin-left: auto;
          margin-right: auto;
        }
      }
    }

    @media (min-width: ${theme.breakpoints.md}px) {
      width: calc(33.33% - ${theme.spacing.base * 2}px);
    }

    @media (min-width: ${theme.breakpoints.xl}px) {
      width: calc(33.33% - ${theme.spacing.base * 6}px);
      margin: ${theme.spacing.base * 5}px 0;
    }
  `}
`

export const IconText = styled.div`
  ${({ theme }) => `
    font-weight: ${theme.fonts.weights.light};
    padding-top: ${theme.spacing.base}px;
    line-height: 1.5;

    strong {
      font-weight: ${theme.fonts.weights.medium};
    }
  `}
`

export const stopSignStyles = css`
  margin-right: auto;
  margin-left: auto;
  max-width: 320px;
  flex-grow: 1;
  flex-shrink: 0;
`

export const decorationStyles1 = css`
  position: absolute !important;
  right: -64px;
  bottom: 300px;
`

export const decorationStyles2 = css`
  position: absolute !important;
  left: -52px;
  bottom: -111px;
`

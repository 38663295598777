import { css } from "@emotion/core";
import styled from '../../styles/theme'
import { Wrapper as WrapperBase } from '../../components/Container'
import arrowRight from '../../assets/images/slick/arrow-right.svg'
import arrowLeft from '../../assets/images/slick/arrow-left.svg'
import TitleBase, { IntroCentered as IntroCenteredBase } from '../../components/Title'


export const Wrapper = styled(WrapperBase)`
  text-align: center;
`

export const SliderWrapper = styled.div`
  ${({ theme }) => `
    outline: none;

    @media(max-width: ${theme.breakpoints.lg - 1}px) {
      margin-left: calc(-7.5vw + ${theme.spacing.base * 2}px);
      margin-right: calc(-7.5vw + ${theme.spacing.base * 2}px);
    }

    @media(max-width: ${theme.breakpoints.xl - 1}px) {
      padding-left: 40px;
      padding-right: 40px;
    }

    .slick-track {
      padding-top: 40px;
    }

    .slick-arrow {
      text-indent: -9999px;
      white-space: nowrap;
      width: 49px;
      height: 49px;
      border: none;
      background-color: transparent;
      position: absolute;
      top: 155px;
      z-index: ${theme.zIndex.overlay - 1};
    }

    .slick-arrow:focus,
    .slick-arrow:active {
      outline: none;
    }

    .slick-arrow.slick-next {
      right: -50px;
      background: url(${arrowRight}) no-repeat center;
    }

    .slick-arrow.slick-prev {
      left: -50px;
      background: url(${arrowLeft}) no-repeat center;
    }
  `}
`

export const AudientWrapper = styled.div`
  ${({ theme }) => `
    cursor: pointer;
    outline: none;

    &:active,
    &:focus-within,
    &:focus {
      outline: none;
    }

    & .picture {
      margin-bottom: 9px;
      transform-origin: 50% 100%;
      transform: scale(0.92);
      transition: ${theme.transitions[1]}, ${theme.transitions[2]};
    }

    &:focus .picture,
    &:hover .picture {
      opacity: 1;
      transform: scale(1);
    }

    & .gatsby-image-wrapper {
      height: 260px;
    }
  `}
`

export const Title = styled(TitleBase)`
  margin-bottom: 0.5em;
`

export const IntroCentered = styled(IntroCenteredBase)`
  ${({ theme }) => `
    margin-top: 0.5em;
    padding-left: ${theme.spacing.base * 2}px;
    padding-right: ${theme.spacing.base * 2}px;
  `}
`

export const decorationStyles = css`
  position: absolute !important;
  left: -150px;
  bottom: -163px;
`

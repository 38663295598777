import { css } from '@emotion/core'

import styled, { theme } from '../../styles/theme'
import { Wrapper as WrapperBase} from '../../components/Container'
import CallToActionBase from '../../components/CallToAction'

import { TextFieldProps } from './types'


export const Wrapper = styled(WrapperBase)`
  ${({ theme }) => `
    background: ${theme.gradients[0]};
  `}
`

export const Step = styled.div`
  padding: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  @media (min-width: ${theme.breakpoints.md}px) {
    max-width: 20%;
  }
`

export const StepsField = styled.div`
  ${({ theme }) => `
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: ${theme.spacing.base * 6}px;
    margin-bottom: ${theme.spacing.base * 6}px;

    @media (min-width: ${theme.breakpoints.md}px) {
      flex-direction: row;
      align-items: flex-start;
    }
  `}
`

export const CirclesField = styled.div`
  ${({ theme }) => `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-top: ${theme.spacing.base * 6}px;
    margin-bottom: ${theme.spacing.base * 6}px;
  `}
`

export const TextField = styled.div<TextFieldProps>`
  ${({ theme, isLast, isImage }) => `
    text-align: center;
    max-width: 400px;
    margin-top: ${theme.spacing.base * 5}px;
    margin-bottom: ${theme.spacing.base * 3}px;
    margin-left: auto;
    margin-right: auto;
    
    h3 {
      margin-bottom: 0.5em;
    }

    p {
      margin: 0px;
    }

    @media (min-width: ${theme.breakpoints.sm}px) {
      margin-top: ${theme.spacing.base * 6}px;
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      margin-top: ${theme.spacing.base * 8}px;
    }

    ${isLast && `margin-top: 0px;` || ''}
    ${isImage && `max-width: 600px;` || ''}
  `}
`

export const ProductArticleWrapper = styled.article`
  ${({ theme }) => `
    margin-top: ${theme.spacing.base * 5}px;
    margin-bottom: ${theme.spacing.base * 4}px;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      margin-top: ${theme.spacing.base * 10}px;
      margin-bottom: ${theme.spacing.base * 12}px;
    }
  `}
`

export const imgStyles = css`
  height: 100%;
  width: 100%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
`

export const marginedImgStyles = css`
  ${imgStyles}

  margin-top: ${theme.spacing.base * 5}px;
  margin-bottom: ${theme.spacing.base * 4}px;

  @media (min-width: ${theme.breakpoints.lg}px) {
    margin-top: ${theme.spacing.base * 10}px;
    margin-bottom: ${theme.spacing.base * 12}px;
  }
`

export const imgDesktopStyles = css`
  height: 100%;
  width: 100%;
`

export const imgCenteredStyles = css`
  max-width: 1024px;
  margin-top: ${theme.spacing.base * 6}px;
  margin-left: auto;
  margin-right: auto;
`

export const imgMobileStyles = css`
  height: 100%;
  margin-bottom: 5%;
`

export const imgMobileStylesFirst = css`
  ${imgMobileStyles}
  width: 100%;
  max-width: 375px;
`

export const imgArrowStyles = css`
  margin-bottom: 20px;

  @media (min-width: ${theme.breakpoints.md}px) {
    margin-top: 68px;
  }
`

export const CallToAction = styled(CallToActionBase)`
  strong {
    display: block;
  }
`

export const decorationStyles1 = css`
  position: absolute !important;
  left: -60px;
  top: 530px;
`

export const decorationStyles2 = css`
  position: absolute !important;
  right: -70px;
  top: -70px;
`

export const decorationStyles3 = css`
  position: absolute !important;
  left: -53px;
  bottom: 360px;
`

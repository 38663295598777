import React, { useCallback } from 'react'
import { scroller } from 'react-scroll'
import CollapseBase from '@material-ui/core/Collapse'

import { useTheme } from '../../styles/theme'

import { CollapsibleProps, CollapseProps } from './types'


const Collapse = (props: CollapseProps) => <CollapseBase {...props} />

export default ({ open, name, parent, children, ...rest }: CollapsibleProps) => {
  const theme = useTheme()
  const handleCollapseEnd = useCallback((node) => {
    name && parent && (
      node.addEventListener(
        'transitionend',
        scroller.scrollTo(open ? name : parent, {
            offset: -theme.heights.header,
            smooth: 'easeInOutQuart',
            isDynamic: true,
          }
        ),
        false
      )
    )
  }, [open])
  
  return (
    <Collapse in={open} addEndListener={handleCollapseEnd} name={name} {...rest}>
      {children}
    </Collapse>
  )
}

import React, { HTMLAttributes } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {Helmet} from 'react-helmet'
// @ts-ignore
import { jsx } from '@emotion/core'
import { useIntl } from 'gatsby-plugin-intl'

import { Button } from '../../components/Buttons'
import { useTheme } from '../../styles/theme'

import {
  Wrapper,
  Container,
  Title,
  Subtitle,
  HeroImages,
  benefitsMobileStyles,
  desktopBannerStyles,
  Buttons,
  decorationStyles,
} from './styles'


export default (props: HTMLAttributes<HTMLElement>) => {
  const data = useStaticQuery(graphql`
    query HeroImages {
      robot: imageSharp(fixed: { originalName: { eq: "hero-robot.png" } }) {
        fixed(quality: 92, width: 456, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      fullBannerDesktopEN: imageSharp(
        fluid: { originalName: { eq: "hero-full-banner-desktop.png" } }
      ) {
        fluid(quality: 92, cropFocus: SOUTH, maxWidth: 1220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      fullBannerDesktopDE: imageSharp(
        fluid: { originalName: { eq: "hero-full-banner-desktop-de.png" } }
      ) {
        fluid(quality: 92, cropFocus: SOUTH, maxWidth: 1220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      fullBannerMobileEN: imageSharp(
        fluid: { originalName: { eq: "hero-full-banner-mobile.png" } }
      ) {
        fluid(quality: 92, cropFocus: SOUTH, maxWidth: 1220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      fullBannerMobileDE: imageSharp(
        fluid: { originalName: { eq: "hero-full-banner-mobile-de.png" } }
      ) {
        fluid(quality: 92, cropFocus: SOUTH, maxWidth: 1220) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      benefitsMobile: imageSharp(
        fixed: { originalName: { eq: "hero-benefits-mobile.png" } }
      ) {
        fixed(quality: 92, width: 375) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      tabletChief: imageSharp(
        fixed: { originalName: { eq: "hero-tablet-chief.png" } }
      ) {
        fixed(quality: 92, width: 442) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      tabletCustomer: imageSharp(
        fixed: { originalName: { eq: "hero-tablet-customer.png" } }
      ) {
        fixed(quality: 92, width: 442) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      tabletOwner: imageSharp(
        fixed: { originalName: { eq: "hero-tablet-owner.png" } }
      ) {
        fixed(quality: 92, width: 442) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco: imageSharp(fixed: { originalName: { eq: "deco1.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  `)
  const { formatMessage, locale } = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md - 1}px)`)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`)
  const decorative = useMediaQuery(`(min-width: ${theme.breakpoints.xxl+120}px)`)

  return (
    <Wrapper {...props}>
      <Helmet
        meta={[
          {
            name: 'description',
            content: formatMessage({ id: 'seo.description' }),
          },
          {
            name: "keywords",
            content: formatMessage({ id: 'seo.keywords' }),
          }
        ]}
      />
      <Container>
        <Title>{formatMessage({ id: "hero.title" })}</Title>
        <Subtitle>{formatMessage({ id: "hero.subtitle" })}</Subtitle>
        <HeroImages>
          {isMobile && (
            <>
              <Image fluid={data[`fullBannerMobile${locale.toUpperCase()}`].fluid} css={benefitsMobileStyles} />
              <Buttons>
                <Button hash="contacts">{formatMessage({ id: "buttons.contact" })}</Button>
              </Buttons>
            </>
          )}
          {isDesktop && (
            <Image fluid={data[`fullBannerDesktop${locale.toUpperCase()}`].fluid} css={desktopBannerStyles} />
          )}
        </HeroImages>
        {decorative && <Image fixed={data.deco.fixed} css={decorationStyles} />}
      </Container>
    </Wrapper>
  );
};

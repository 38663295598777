export default ([
  {
    key: 'start',
    image: 'start',
  },
  {
    key: 'overview',
    image: 'overview',
  },
  {
    key: 'revenue',
    image: 'revenue',
  }
])

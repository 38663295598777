import React from 'react'
import Drawer from '@material-ui/core/SwipeableDrawer'
import Hamburger from 'hamburger-react'
import { useIntl } from 'gatsby-plugin-intl'
import { Link } from 'gatsby'

import MenuLink from '../../components/MenuLink'
import homepageStructure from '../../homepageStructure'

import { ToggleButton, DrawerContent, MenuItem } from './styles'
import { MenuMobileProps } from './types'


export default ({
  open,
  setOpen,
  handleClose,
  handleOpen,
  originalPath,
}: MenuMobileProps) => {
  const { locale } = useIntl()
  const altLang = locale === 'en' ? 'de' : 'en'

  return (
    <>
      <ToggleButton>
        <Hamburger toggled={open} toggle={setOpen} color="#fff" size={28} />
      </ToggleButton>

      <Drawer
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
        anchor="right"
      >
        <DrawerContent>
          {homepageStructure.map(({ hash, menu }) => (
            menu && hash ? (
              <MenuItem key={hash}>
                <MenuLink hash={hash} menu={menu} onClick={handleClose} />
              </MenuItem>
            ) : null
          ))}
          <MenuItem>
            <Link to={`/${locale}/blog`}>
              Blog
            </Link>
          </MenuItem>
          <MenuItem>
            <Link to={`/${altLang}${originalPath === '/' ? '' : originalPath}`}>
              {altLang.toUpperCase()}
            </Link>
          </MenuItem>
        </DrawerContent>
      </Drawer>
    </>
  )
}

import ModalBase from '@material-ui/core/Modal'

import styled from '../../styles/theme'
import GearIcon from '../../assets/images/gear.svg'


export const CookiesButton = styled.div`
  ${({ theme }) => `
    background-color: ${theme.palette.background.dark};
    position: absolute;
    border-radius: 25px;
    position: fixed;
    bottom: ${theme.spacing.base * 2.5}px;
    left: ${theme.spacing.base * 2.5}px;
    color: ${theme.palette.text.light};
    font-size: ${theme.fonts.sizes.xs};
    font-weight: ${theme.fonts.weights.medium};
    padding: ${theme.spacing.base * 1.5}px ${theme.spacing.base * 2.5}px;
    padding-left: ${theme.spacing.base * 5}px;
    background-image: url(${GearIcon});
    background-position: ${theme.spacing.base}px 50%;
    background-repeat: no-repeat;
    letter-spacing: 1px;
    cursor: pointer;
    user-select: none;
    
    @media (min-width: ${theme.breakpoints.xl}px) {
      left: calc(50% - 600px);
    }
  `}
`

export const Modal = styled(ModalBase)`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: ${theme.zIndex.navbar + 1} !important;
  `}
`

export const CookiesDialog = styled.article`
  ${({ theme }) => `
    background-color: ${theme.palette.background.dark};
    max-width: 360px;
    padding: ${theme.spacing.base * 3}px ${theme.spacing.base * 5}px;
    border-radius: ${theme.spacing.base * 2.5}px;
  `}
`

export const Message = styled.p`
  ${({ theme }) => `
    color: ${theme.palette.text.light};
    text-align: center;
    line-height: 1.5;
    font-weight: ${theme.fonts.weights.light};
  `}
`

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
`

export const Button = styled.button`
  ${({ theme }) => `
    background: ${theme.palette.background.light};
    border-radius: ${theme.spacing.base * 2}px;
    color: ${theme.palette.text.dark};
    font-weight: ${theme.fonts.weights.medium};
    padding: ${theme.spacing.base * 0.5}px ${theme.spacing.base * 2}px;
    min-width: ${theme.spacing.base * 11}px;
    text-align: center;
    border: 0;
    cursor: pointer;
  `}
`

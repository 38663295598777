import React, { HTMLProps } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'

import { IntroCentered } from '../../../../components/Title'

import { AdvantagesWrapper, Title, ExtensionImage } from './styles'
import textData from './advantagesData'


export default (props: HTMLProps<HTMLDivElement>) => {
  const imagesData = useStaticQuery(graphql`
    query {
      small: imageSharp(fixed: {originalName: {eq: "ai-advantages.png"}}) {
        fixed(quality: 92, width: 280, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      big: imageSharp(fixed: {originalName: {eq: "ai-advantages.png"}}) {
        fixed(quality: 92, width: 425, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  `)
  const { left, right } = textData.list
  const { formatMessage } = useIntl()

  return (
    <AdvantagesWrapper {...props}>
      <div className="list-wrapper">
        <ul className="left">
          {left.map(text => (
            <li
              key={text}
              dangerouslySetInnerHTML={{ __html: formatMessage({ id: `ai.advantages.left.${text}` }) }}
            />
          ))}
        </ul>
        <ExtensionImage big={imagesData.big.fixed} small={imagesData.small.fixed} />
        <ul className="right">
          {right.map(text => (
            <li
              key={text}
              dangerouslySetInnerHTML={{ __html: formatMessage({ id: `ai.advantages.right.${text}` }) }}
            />
          ))}
        </ul>
      </div>
    </AdvantagesWrapper>
  )
}

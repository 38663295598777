import { css } from '@emotion/core'

import styled, { theme } from '../../styles/theme'


export const buttonBasicStyles = css`
  display: inline-block;
  padding: 0.8em;
  min-width: 42%;
  text-decoration: none;
  border-radius: 4px;
  border-width: 1px;
  border-style: solid;
  font-size: ${theme.fonts.sizes.sm};
  box-shadow: ${theme.shadows[0]};
  transition: ${theme.transitions[0]}, ${theme.transitions[3]}, ${theme.transitions[4]};
`

export const buttonFilledStyles = css`
  ${buttonBasicStyles}
  
  border-color: ${theme.palette.primary.main};
  background-color: ${theme.palette.primary.main};
  color: ${theme.palette.text.light};
  
  &:hover {
    background-color: ${theme.palette.primary.light};
  }
  
  &:active {
    background-color: ${theme.palette.primary.main};
  }
`

export const buttonOutlinedStyles = css`
  ${buttonBasicStyles}

  border-color: ${theme.palette.primary.grey};
  background-color: ${theme.palette.background.light};
  color: ${theme.palette.text.main};

  &:hover {
    border-color: ${theme.palette.background.light};
    background-color: ${theme.palette.primary.main};
    color: ${theme.palette.text.light};
  }
  
  &:active {
    background-color: ${theme.palette.primary.main};
  }
`

export const Buttons = styled.div`
  ${({ theme }) => `
    padding-top: ${theme.spacing.base * 4}px;
    padding-bottom: ${theme.spacing.base * 4}px;
    text-align: center;
    display: flex;
    justify-content: space-around;
    max-width: 360px;
    margin-left: auto;
    margin-right: auto;

    @media(min-width: ${theme.breakpoints.md}px) {
      padding-top: ${theme.spacing.base * 7}px;
      padding-bottom: ${theme.spacing.base * 9}px;
    }
  `}
`

export const ToggleButton = styled.button`
  ${buttonOutlinedStyles}

  cursor: pointer;
`

export const SubmitButton = styled.input`
  ${buttonFilledStyles}

  cursor: pointer;
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

import React, { HTMLProps } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Image from 'gatsby-image'

import { useTheme } from '../../../../styles/theme'

import {
  ExtensionList,
  ExtensionImage,
  decorationStyles1,
  decorationStyles2,
  decorationStyles3,
} from './styles'
import textData from './extensionData'


export default (props: HTMLProps<HTMLDivElement>) => {
  const imagesData = useStaticQuery(graphql`
    query {
      small: imageSharp(fixed: {originalName: {eq: "ai-extension.png"}}) {
        fixed(quality: 92, width: 280, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      big: imageSharp(fixed: {originalName: {eq: "ai-extension.png"}}) {
        fixed(quality: 92, width: 425, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco1: imageSharp(fixed: { originalName: { eq: "deco4.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco2: imageSharp(fixed: { originalName: { eq: "deco2.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco3: imageSharp(fixed: { originalName: { eq: "deco0.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  `)
  const { half1, half2 } = textData.list
  const { formatMessage } = useIntl()
  const theme = useTheme()
  const decorative = useMediaQuery(
    `(min-width: ${theme.breakpoints.xxl + 120}px)`
  )

  return (
    <>
      <ExtensionList {...props}>
        <div className="half-1-wrapper">
          <ul className="half-1">
            {half1.map(text => (
              <li
                key={text}
                dangerouslySetInnerHTML={{ __html: formatMessage({ id: `ai.extension.half1.${text}` }) }}
              />
            ))}
          </ul>
          <ExtensionImage big={imagesData.big.fixed} small={imagesData.small.fixed} />
        </div>
        <ul className="half-2">
          {half2.map(text => (
            <li
              key={text}
              dangerouslySetInnerHTML={{ __html: formatMessage({ id: `ai.extension.half2.${text}` }) }}
            />
          ))}
        </ul>
      </ExtensionList>
      {decorative && (
        <>
          <Image fixed={imagesData.deco1.fixed} css={decorationStyles1} />
          <Image fixed={imagesData.deco2.fixed} css={decorationStyles2} />
          <Image fixed={imagesData.deco3.fixed} css={decorationStyles3} />
        </>
      )}
    </>
  )
}

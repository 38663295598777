import { css } from '@emotion/core'

import styled from '../../styles/theme'
import { Wrapper as WrapperBase } from '../../components/Container'
import PhoneIcon from '../../assets/images/phone-icon.svg'
import EmailIcon from '../../assets/images/email-icon.svg'

import ParagraphBase from './Paragraph'


export const Wrapper = styled(WrapperBase)`
  ${({ theme }) => `
    background: ${theme.palette.background.light};
    padding-bottom: ${theme.spacing.base * 5.5}px;

    .gatsby-image-wrapper {
      margin: 0 auto ${theme.spacing.base * 4.75}px;
    }

    @media (min-width: ${theme.breakpoints.xl}px) {
      padding-top: ${theme.spacing.base * 5}px;
      padding-bottom: ${theme.spacing.base * 10}px;
    }
  `}
`

export const Paragraph = styled(ParagraphBase)`
  ${({ theme, weight, type }) => `
    line-height: 1.5;
    font-weight: ${theme.fonts.weights[weight || "light"]};

    a {
      color: ${theme.palette.text.main};
      text-decoration: none;
    }

    ${(type && `
      display: block;
      padding-top: 30px;
      background-repeat: no-repeat;
      background-position: center top;
      margin-top: ${theme.spacing.base * 2}px;

      @media (min-width: ${theme.breakpoints.md}px) {
        padding-top: 0;
        padding-left: 30px;
        background-position: left center;
      }
    `) || ''}
    ${(type === "phone" && `background-image: url(${PhoneIcon});`) || ''}
    ${(type === "email" && `background-image: url(${EmailIcon});`) || ''}
  `}
`

export const InnerWrapper = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.md}px) {
      display: flex;
    }
  `}
`

export const RobotAndContacts = styled.div`
  ${({ theme }) => `
    text-align: center;

    @media (min-width: ${theme.breakpoints.md}px) {
      text-align: left;
      margin-right: ${theme.spacing.base * 3}px;
    }

    @media (min-width: ${theme.breakpoints.lg}px) {
      margin-top: ${theme.spacing.base * 5}px;
      margin-right: 0;
      flex: 0 1 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      
      .gatsby-image-wrapper {
        flex-grow: 1;
        width: 100%;
      }
    }
  `}
`

export const ContactDetails = styled.div`
  ${({ theme }) => `
    @media (min-width: ${theme.breakpoints.lg}px) {
      margin-left: auto;
      margin-right: ${theme.spacing.base * 4}px;
    }

    @media (min-width: ${theme.breakpoints.xl}px) {
      margin-left: 25%;
    }
  `}
`

export const decorationStyles1 = css`
  position: absolute !important;
  left: -48px;
  top: 230px;
`

export const decorationStyles2 = css`
  position: absolute !important;
  right: -52px;
  bottom: 200px;
`

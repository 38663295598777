import React from 'react'
import { Link } from 'react-scroll'
import { Link as IntlLink } from 'gatsby-plugin-intl'
// @ts-ignore
import { jsx } from '@emotion/core'

import { useTheme } from '../../styles/theme'

import { ButtonProps } from './types'
import { buttonOutlinedStyles, buttonFilledStyles } from './styles'


// TODO: extract Link into a separate component
export default ({ children, variant, hash, to }: ButtonProps) => {
  const theme = useTheme();
  const buttonStyles = variant === 'outlined' ? buttonOutlinedStyles : buttonFilledStyles
  
  return hash ? (
    <Link
      to={hash}
      href={`#${hash}`}
      offset={-theme.heights.header}
      hashSpy
      smooth
      css={buttonStyles}
    >
      {children}
    </Link>
  ) : (
    <IntlLink css={buttonStyles} to={to || '/'}>{children}</IntlLink>
  )
}

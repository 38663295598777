import { useState, useCallback } from 'react'

import { FormData } from './types'


// https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
export const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
export const phoneRegex = /^\+?[\d\s\(\)\-]+$/

export const contactFormEndpoint = process.env.SENDFORM_API as string

export const useFetch = () => {
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState()
  const [result, setResult] = useState()
  const sender = useCallback((formData: FormData) => {
    setIsSubmitting(true)

    try {
      fetch(contactFormEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          content: JSON.stringify(formData),
          ...formData,
        }),
      }).then(result => {
        if (result.ok) {
          return result.json()
        } else {
          throw new Error(result.statusText)
        }
      }).then((result) => {
        setResult(result)
      }).catch(error => {
        setError(error)
      })
    } catch(error) {
      setError(error)
    } finally {
      setIsSubmitting(false)
    }
  }, [error, result])

  return {
    sender,
    isSubmitting,
    error,
    result,
  }
}

export default {
  operational: [
    {
      key: 'severin_latkovic',
      role: true,
      position: true,
      staff: 'operational',
    },
    {
      key: 'ivo_blohm',
      position: true,
      role: true,
      staff: 'operational',
    },
    {
      key: 'torsten_petersen',
      position: true,
      role: false,
      staff: 'operational',
    },
    {
      key: 'dieter_raebel',
      position: true,
      role: false,
      staff: 'operational',
    },
    {
      key: 'ole_grunert',
      position: true,
      role: true,
      staff: 'operational',
    },
    {
      key: 'rich_harpur',
      position: true,
      role: true,
      staff: 'operational',
    },
    {
      key: 'max_nadig',
      position: true,
      role: true,
      staff: 'operational',
    },
    {
      key: 'jens_eirik_saethre',
      position: true,
      role: true,
      staff: 'operational',
    },
  ],
  board: [
    {
      key: 'torsten_petersen',
      position: true,
      role: false,
      staff: 'board',
    },
    {
      key: 'dieter_raebel',
      position: true,
      role: false,
      staff: 'board',
    },
    {
      key: 'markus_bronniman',
      position: true,
      role: false,
      staff: 'board',
    },
    {
      key: 'beatrice_fischer',
      position: true,
      role: false,
      staff: 'board',
    },
  ],
};

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { Link } from 'gatsby'

import MenuLink from '../../components/MenuLink'
import homepageStructure from '../../homepageStructure'

import { MenuItem } from './styles'
import { MenuProps } from './types'


export default ({ originalPath }: MenuProps) => {
  const { locale } = useIntl()
  const altLang = locale === 'en' ? 'de' : 'en'

  return (
    <>
      {homepageStructure.map(({ hash, menu }) => (
        menu && hash && (
          <MenuItem key={hash}>
            <MenuLink hash={hash} menu={menu} />
          </MenuItem>
        )
      ))}
      <MenuItem>
        <Link to={`/${locale}/blog`}>
          Blog
        </Link>
      </MenuItem>
      <MenuItem>
        <Link to={`/${altLang}${originalPath === '/' ? '' : originalPath}`}>
          {altLang.toUpperCase()}
        </Link>
      </MenuItem>
    </>
  )
}

import React from 'react'
import Image from 'gatsby-image'

import { ArticleImageProps } from './types'
import { ArticleImageWrapper, articleIconStyles } from './styles'


export default ({ fixedImage }: ArticleImageProps) => (
  <ArticleImageWrapper>
     <Image fixed={fixedImage} style={articleIconStyles} />
  </ArticleImageWrapper>
)

import React from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useIntl } from 'gatsby-plugin-intl';

import { FooterLogo } from '../../components/Logo';
import { useTheme } from '../../styles/theme';

import MenuDesktop from '../Menu/MenuDesktop';

import ContactLink from './ContactLink';
import { FooterWrapper, FooterContainer, FooterColumn, FooterText, Contact, MobileBlock } from './styles';
import { FooterProps } from './types';

export default ({ originalPath }: FooterProps) => {
  const theme = useTheme();
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md - 1}px)`);
  const isTablet = useMediaQuery(
    `(min-width: ${theme.breakpoints.md}px) and (max-width: ${theme.breakpoints.lg - 1}px)`
  );
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px)`);
  const currentYear = new Date().getFullYear();

  const address = (
    <div>
      <FooterText light>{formatMessage({ id: 'footer.address.title' })}</FooterText>
      <FooterText light>{formatMessage({ id: 'footer.address.line1' })}</FooterText>
      <FooterText light>{formatMessage({ id: 'footer.address.line2' })}</FooterText>
      <FooterText light>{formatMessage({ id: 'footer.address.line3' })}</FooterText>
      {isDesktop && (
        <FooterText
          space
          light
        >
          © {currentYear} {formatMessage({ id: 'footer.copyright' })}
        </FooterText>
      )}
    </div>
  );

  const contact = (
    <div>
      <FooterText light>
        <ContactLink type='phone'>{formatMessage({ id: 'phone' })}</ContactLink>
      </FooterText>
      {isDesktop && (
        <FooterText light>
          <ContactLink type='email'>{formatMessage({ id: 'email' })}</ContactLink>
        </FooterText>
      )}
      {isMobile && (
        <FooterText
          space
          light
        >
          <ContactLink type='email'>{formatMessage({ id: 'email' })}</ContactLink>
        </FooterText>
      )}
    </div>
  );

  return (
    <FooterWrapper>
      <FooterContainer>
        {isDesktop && (
          <>
            <FooterColumn>
              <FooterLogo />
            </FooterColumn>
            <FooterColumn>
              <FooterText footerSpacing>{formatMessage({ id: 'footer.title' })}</FooterText>
              <Contact>
                {address}
                {contact}
              </Contact>
            </FooterColumn>
            <FooterColumn>
              <MenuDesktop originalPath={originalPath} />
            </FooterColumn>
          </>
        )}
        {isTablet && (
          <>
            <FooterColumn>
              <FooterLogo />
              <FooterText
                space
                footerSpacing
              >
                {formatMessage({ id: 'footer.title' })}
              </FooterText>
              {address}
              {contact}
            </FooterColumn>
            <FooterColumn>
              <MenuDesktop originalPath={originalPath} />
              <FooterText
                space
                light
              >
                {formatMessage({ id: 'footer.copyright' })}
              </FooterText>
            </FooterColumn>
          </>
        )}
        {isMobile && (
          <>
            <FooterColumn>
              <MobileBlock>
                <MenuDesktop originalPath={originalPath} />
              </MobileBlock>
              <MobileBlock>
                <FooterText footerSpacing>{formatMessage({ id: 'footer.title' })}</FooterText>
                {address}
              </MobileBlock>
              <MobileBlock>
                <FooterLogo />
              </MobileBlock>
              <MobileBlock>{contact}</MobileBlock>
              <MobileBlock>
                <FooterText light>{formatMessage({ id: 'footer.copyright' })}</FooterText>
              </MobileBlock>
            </FooterColumn>
          </>
        )}
      </FooterContainer>
    </FooterWrapper>
  );
};

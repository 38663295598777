import React, { forwardRef, RefObject } from 'react'

import { Fieldset, Star } from './styles'
import { FieldProps } from './types'


export default forwardRef(({
  required,
  placeholder,
  fieldName,
  label,
  multiline,
  errors,
  inputProps,
  ...rest
}: FieldProps, ref) => (
  <Fieldset
    required={required}
    {...(errors && errors[fieldName] && {className: 'error'})}
    {...rest}
  >
    {label && <label htmlFor={fieldName}>{label}{required && <Star />}</label>}
    {
      multiline
      ?
      (
        <textarea
          id={fieldName}
          name={fieldName}
          placeholder={placeholder}
          rows={6}
          ref={ref as RefObject<HTMLTextAreaElement>}
          {...inputProps}
        />
      )
      :
      (
        <input
          id={fieldName}
          name={fieldName}
          placeholder={placeholder}
          ref={ref as RefObject<HTMLInputElement>}
          {...inputProps}
        />
      )
    }
  </Fieldset>
))

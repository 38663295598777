export default ([
  {
    title: 'I OWN A RESTAURANT',
    text: 'product level forecast',
    image: 'audient-owner.png',
    key: 'owner',
  },
  {
    title: 'RESTAURANT MANAGER',
    text: 'reduce food waste',
    image: 'audient-manager.png',
    key: 'manager',
  },
  {
    title: 'I AM A CHEF',
    text: 'data-based menu planning',
    image: 'audient-chef.png',
    key: 'chef',
  },
  {
    title: 'RESTAURANT CUSTOMER',
    text: 'the joy of eating meals',
    image: 'audient-customer.png',
    key: 'customer',
  },
])

import { createElement } from 'react'

import styled from '../../styles/theme'

import { BlockTitleProps } from './types'


export const Title = styled((
  { component, line, children, center, color,weight,right, ...props }: BlockTitleProps
) => createElement(component || 'h2', props, children))`
  ${({ theme, size, line, center, color, weight, right }) => `
    font-size: ${theme.fonts.sizes[size || 'title']};
    font-weight: ${theme.fonts.weights.bold};
    ${weight ? `font-weight: ${theme.fonts.weights[weight]};` : `font-weight: ${theme.fonts.weights.bold};`}
    letter-spacing: 1px;
    ${color ? `color: ${theme.palette.primary[color]};` : `color: ${theme.palette.text.dark};`} 
    ${line ? `line-height: ${line};` : ''}
    ${center ? 'text-align: center;' : ''}
    ${right ? 'text-align: right;' : ''}
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
  `}
`

export const Intro = styled.div<{ wide?: boolean; center?: boolean; bold?: boolean }>`
  ${({ theme, wide, center, bold }) => `
    max-width: ${wide ? `720px` : `580px`};
    margin-left: auto;
    margin-right: auto;
    letter-spacing: initial;
    line-height: 1.5;
    font-size: ${theme.fonts.sizes.regular};
    font-weight: ${theme.fonts.weights[bold ? 'bold' : 'light']};
    margin-bottom: ${theme.spacing.base * 4}px;
    ${center ? 'text-align: center;' : ''}
  `}
`

export const IntroCentered = styled(Intro)`
  text-align: center;
`

import React, { HTMLAttributes, useCallback, useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { scroller } from 'react-scroll';
import { useIntl } from 'gatsby-plugin-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Image from 'gatsby-image';

import ContainerBase from 'components/Container';
import Title, { IntroCentered } from 'components/Title';
import { Button, ToggleButton } from 'components/Buttons';
import { useTheme } from 'styles/theme';

import Member from './Member';
import {
  Wrapper,
  Staff,
  Buttons,
  decorationStyles1,
  decorationStyles2,
  decorationStyles3,
  decorationStyles4,
} from './styles';
import teamData from './teamData';

export default (props: HTMLAttributes<HTMLElement>) => {
  const imagesData = useStaticQuery(graphql`
    query {
      severin_latkovic: imageSharp(fixed: { originalName: { eq: "severin_latkovic.png" } }) {
        fixed(quality: 92, width: 190) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      ivo_blohm: imageSharp(fixed: { originalName: { eq: "ivo_blohm.png" } }) {
        fixed(quality: 92, width: 190) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      torsten_petersen: imageSharp(fixed: { originalName: { eq: "torsten_petersen.png" } }) {
        fixed(quality: 92, width: 190) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      dieter_raebel: imageSharp(fixed: { originalName: { eq: "dieter_raebel.png" } }) {
        fixed(quality: 92, width: 190) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      ole_grunert: imageSharp(fixed: { originalName: { eq: "ole_grunert.png" } }) {
        fixed(quality: 92, width: 190) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      rich_harpur: imageSharp(fixed: { originalName: { eq: "rich_harpur.png" } }) {
        fixed(quality: 92, width: 190) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      max_nadig: imageSharp(fixed: { originalName: { eq: "max_nadig.png" } }) {
        fixed(quality: 92, width: 190) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      jens_eirik_saethre: imageSharp(fixed: { originalName: { eq: "jens_eirik_saethre.png" } }) {
        fixed(quality: 92, width: 190) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      markus_bronniman: imageSharp(fixed: { originalName: { eq: "markus_bronniman.png" } }) {
        fixed(quality: 92, width: 190) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      beatrice_fischer: imageSharp(fixed: { originalName: { eq: "beatrice_fischer.png" } }) {
        fixed(quality: 92, width: 190) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco1: imageSharp(fixed: { originalName: { eq: "deco3.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco2: imageSharp(fixed: { originalName: { eq: "deco4.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco3: imageSharp(fixed: { originalName: { eq: "deco0.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco4: imageSharp(fixed: { originalName: { eq: "deco7.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  `);
  const { formatMessage } = useIntl();
  const [extended, setExtended] = useState(false);
  const theme = useTheme();
  const btnTextLearnMore = formatMessage({
    id: extended ? 'buttons.summary' : 'buttons.learn-more',
  });
  const decorative = useMediaQuery(`(min-width: ${theme.breakpoints.xxl + 120}px)`);

  const handleClick = useCallback(() => {
    setExtended(!extended);
    scroller.scrollTo('team', {
      offset: -theme.heights.header,
      smooth: 'easeInOutQuart',
      isDynamic: true,
    });
  }, [extended]);

  return (
    <>
      <Wrapper {...props}>
        <ContainerBase>
          <Title center>{formatMessage({ id: 'team.title' })}</Title>
          <IntroCentered>{formatMessage({ id: 'team.intro' })}</IntroCentered>
          <Title center>{formatMessage({ id: 'team.operationalTitle' })}</Title>
          <Staff extended={extended}>
            {teamData.operational.map((member, idx) => (
              <Member
                key={member.key}
                extended={extended}
                member={member}
                image={imagesData[member.key].fixed}
                className={idx % 2 === 0 ? 'odd' : 'even'}
              />
            ))}
            <div style={{ width: '100%', marginBottom: '4rem' }}>
              <Title center>{formatMessage({ id: 'team.boardTitle' })}</Title>
            </div>
            {teamData.board.map((member, idx) => (
              <Member
                key={member.key}
                extended={extended}
                member={member}
                image={imagesData[member.key].fixed}
                className={idx % 2 === 0 ? 'odd' : 'even'}
              />
            ))}
          </Staff>
          <Buttons>
            <ToggleButton onClick={handleClick}>{btnTextLearnMore}</ToggleButton>
            <Button hash='contacts'>{formatMessage({ id: 'buttons.contact' })}</Button>
          </Buttons>
          {decorative ? (
            <>
              <Image
                fixed={imagesData.deco1.fixed}
                css={decorationStyles1(extended)}
              />
              <Image
                fixed={imagesData.deco2.fixed}
                css={decorationStyles2}
              />
              {extended ? (
                <>
                  <Image
                    fixed={imagesData.deco3.fixed}
                    css={decorationStyles3(extended)}
                  />
                  <Image
                    fixed={imagesData.deco4.fixed}
                    css={decorationStyles4}
                  />
                </>
              ) : null}
            </>
          ) : null}
        </ContainerBase>
      </Wrapper>
    </>
  );
};

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import useMediaQuery from '@material-ui/core/useMediaQuery'

import { useTheme } from '../../styles/theme'

import { Articles, robotLearnMore, robotContactUs } from './styles'


export default ({ children }: any) => {
  const imagesData = useStaticQuery(graphql`
    query {
      robotLearnMore: imageSharp(fixed: {originalName: {eq: "ai-robot-learn-more.png"}}) {
        fixed(quality: 92, width: 188) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      robotContactUs: imageSharp(fixed: {originalName: {eq: "ai-robot-contact-us.png"}}) {
        fixed(quality: 92, width: 188) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  `)

  const theme = useTheme()
  const isWideDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.xxl}px)`)

  return (
    <>
      {isWideDesktop && <Image fixed={imagesData.robotLearnMore.fixed} style={robotLearnMore} />}
      <Articles>
        {children}
      </Articles>
      {isWideDesktop && <Image fixed={imagesData.robotContactUs.fixed} style={robotContactUs} />}
    </>
  )
}

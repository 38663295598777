import React, { HTMLAttributes, useCallback } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Slider from 'react-slick'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useIntl } from 'gatsby-plugin-intl'

import { useTheme } from '../../styles/theme'

import Audient from './Audient'
import { Wrapper, SliderWrapper } from './styles'
import data from './audienceData'
import { EdgeProps } from './types'


// TODO: add functionality, described in the INTRO
export default (props: HTMLAttributes<HTMLElement>) => {
  const imagesData = useStaticQuery(graphql`
    query {
      images: allFile(filter: {childImageSharp: {fixed: {originalName: {regex: "/audient/"}}}}) {
        edges {
          node {
            childImageSharp {
              fixed(quality: 92, width: 230) {
                originalName
                height
                ...GatsbyImageSharpFixed_tracedSVG
              }
            }
          }
        }
      }
    }
  `)
  // TODO: move the following queries to a theme or some custom hook
  const theme = useTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm - 1}px)`)
  const isSmallTablet = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px) and (max-width: ${theme.breakpoints.md - 1}px)`)
  const isTablet = useMediaQuery(`(min-width: ${theme.breakpoints.md}px) and (max-width: ${theme.breakpoints.lg - 1}px)`)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.lg}px) and (max-width: ${theme.breakpoints.xl - 1}px)`)
  const isLargeDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.xl}px)`)
  const { formatMessage } = useIntl()

  const slidesToShow = React.useCallback(() => {
    if (isMobile) return ({
      slidesToShow: 1,
      slidesToScroll: 1,
    })
    if (isSmallTablet) return ({
      slidesToShow: 2,
      slidesToScroll: 2,
    })
    if (isTablet) return ({
      slidesToShow: 3,
      slidesToScroll: 3,
    })
    if (isDesktop) return ({
      slidesToShow: 4,
      slidesToScroll: 4,
    })
    if (isLargeDesktop) return ({
      slidesToShow: 4,
      slidesToScroll: 4,
    })
  }, [isMobile, isSmallTablet, isTablet, isDesktop, isLargeDesktop])

  const audientRenderer = useCallback((audient) => {
    const image = imagesData.images.edges.find(({ node }: EdgeProps) => (
      node.childImageSharp.fixed.originalName === audient.image
    ))

    if (image) {
      return (
        <Audient
          key={audient.key}
          fixedImage={image.node.childImageSharp.fixed}
          title={formatMessage({ id: `audience.${audient.key}.title` })}
          text={formatMessage({ id: `audience.${audient.key}.text` })}
        />
      )
    } else {
      console.error(Error(`Missing "${audient.image}" image in Audience block`))
    }
  }, [])

  return (
    <Wrapper {...props}>
      <SliderWrapper>
        <Slider
          dots={false}
          infinite={true}
          {...slidesToShow()}
        >
          {data.map(audient => audientRenderer(audient))}
        </Slider>
      </SliderWrapper>
    </Wrapper>
  )
}

import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Image from 'gatsby-image'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useIntl } from 'gatsby-plugin-intl'

import { useTheme } from '../../styles/theme'
import Title, { IntroCentered } from '../../components/Title'

import {
  Step,
  StepsField,
  CirclesField,
  TextField,
  imgArrowStyles,
  imgCenteredStyles,
  imgDesktopStyles,
  imgMobileStyles,
} from './styles'


export default () => {
  const data = useStaticQuery(graphql`
    query ExtensionContainer {
      robot: imageSharp(
        fixed: { originalName: { eq: "business-robot-cinema.png" } }
      ) {
        fixed(quality: 92, width: 476, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      monitor1: imageSharp(fluid: { originalName: { eq: "pe1.png" } }) {
        fluid(quality: 92, maxWidth: 1024, fit: INSIDE) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      monitor1mobile: imageSharp(fluid: { originalName: { eq: "pe1m.png" } }) {
        fluid(quality: 92, maxWidth: 768, fit: INSIDE) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
      circle1: imageSharp(fixed: { originalName: { eq: "pe2m.png" } }) {
        fixed(quality: 92, width: 250, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      circle2: imageSharp(fixed: { originalName: { eq: "pe3m.png" } }) {
        fixed(quality: 92, width: 250, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      circle3: imageSharp(fixed: { originalName: { eq: "pe4m.png" } }) {
        fixed(quality: 92, width: 250, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      arrow: imageSharp(fixed: { originalName: { eq: "pe6.png" } }) {
        fixed(quality: 92, width: 40, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      arrowFlip: imageSharp(fixed: { originalName: { eq: "pe6_flip.png" } }) {
        fixed(quality: 92, width: 20, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      step1: imageSharp(fixed: { originalName: { eq: "pe2.png" } }) {
        fixed(quality: 92, width: 200, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      step2: imageSharp(fixed: { originalName: { eq: "pe3.png" } }) {
        fixed(quality: 92, width: 200, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      step3: imageSharp(fixed: { originalName: { eq: "pe4.png" } }) {
        fixed(quality: 92, width: 200, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      step4: imageSharp(fixed: { originalName: { eq: "pe5.png" } }) {
        fixed(quality: 92, width: 200, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      monitor2: imageSharp(fixed: { originalName: { eq: "pe7.png" } }) {
        fixed(quality: 92, width: 600, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      monitor2mobile: imageSharp(fixed: { originalName: { eq: "pe7.png" } }) {
        fixed(quality: 92, width: 280, fit: CONTAIN) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco1: imageSharp(fixed: { originalName: { eq: "deco7.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
      deco2: imageSharp(fixed: { originalName: { eq: "deco2.png" } }) {
        fixed(quality: 92, width: 120) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
      }
    }
  `)
  const { formatMessage } = useIntl()
  const theme = useTheme()
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.md - 1}px)`)
  const isDesktop = useMediaQuery(`(min-width: ${theme.breakpoints.md}px)`)

  return (
    <>
      <TextField>
        <Title size="regular" component="h3">
          {formatMessage({ id: 'product.extension.title' })}
        </Title>
      </TextField>
      {isMobile && (
        <>
          <Image fluid={data.monitor1mobile.fluid} css={imgCenteredStyles} />
          <CirclesField>
            <Image fixed={data.circle1.fixed} css={imgMobileStyles} />
            <Image fixed={data.circle2.fixed} css={imgMobileStyles} />
            <Image fixed={data.circle3.fixed} css={imgMobileStyles} />
          </CirclesField>
        </>
      )}
      {isDesktop && (
        <Image fluid={data.monitor1.fluid} css={imgCenteredStyles} />
      )}
      <TextField>
        <Title size="regular" component="h3">{formatMessage({ id: 'product.extension.steps.title' })}</Title>
      </TextField>
      <StepsField>
        <Step>
          <Image fixed={data.step1.fixed} />
          <IntroCentered>{formatMessage({ id: 'product.extension.steps.step1' })}</IntroCentered>
        </Step>
        {isMobile && (
          <Image fixed={data.arrowFlip.fixed} css={imgArrowStyles} />
        )}
        {isDesktop && <Image fixed={data.arrow.fixed} css={imgArrowStyles} />}
        <Step>
          <Image fixed={data.step2.fixed} />
          <IntroCentered>{formatMessage({ id: 'product.extension.steps.step2' })}</IntroCentered>
        </Step>
        {isMobile && (
          <Image fixed={data.arrowFlip.fixed} css={imgArrowStyles} />
        )}
        {isDesktop && <Image fixed={data.arrow.fixed} css={imgArrowStyles} />}
        <Step>
          <Image fixed={data.step3.fixed} />
          <IntroCentered>{formatMessage({ id: 'product.extension.steps.step3' })}</IntroCentered>
        </Step>
        {isMobile && (
          <Image fixed={data.arrowFlip.fixed} css={imgArrowStyles} />
        )}
        {isDesktop && <Image fixed={data.arrow.fixed} css={imgArrowStyles} />}
        <Step>
          <Image fixed={data.step4.fixed} />
          <IntroCentered>{formatMessage({ id: 'product.extension.steps.step4' })}</IntroCentered>
        </Step>
      </StepsField>
      <TextField isImage="true">
        {isMobile && (
          <Image fixed={data.monitor2mobile.fixed} css={imgMobileStyles} />
        )}
        {isDesktop && (
          <Image fixed={data.monitor2.fixed} css={imgDesktopStyles} />
        )}
      </TextField>
    </>
  )
}

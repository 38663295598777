import React, { useRef, useEffect } from 'react'
import { useForm } from 'react-hook-form'
// @ts-ignore
import { NotificationContainer, NotificationManager } from 'react-notifications'
import { useIntl } from 'gatsby-plugin-intl'

import { SubmitButton } from '../../../../components/Buttons'

import Field from '../Field'

import { Wrapper, Form, Note, Buttons } from './styles'
import { emailRegex, phoneRegex, useFetch } from './utils'
import { FormData } from './types'


export default () => {
  const { register, handleSubmit, errors } = useForm()
  const { sender, isSubmitting, error, result } = useFetch()
  const formElement = useRef<HTMLFormElement>(null)
  const { formatMessage } = useIntl()

  const onSubmit = (data: FormData) => sender(data)

  useEffect(() => {
    if (error) {
      // @ts-ignore
      const { message } = error
      NotificationManager.error(
        message || formatMessage({ id: 'contact.form.notifications.error.fallback-message' }),
        formatMessage({ id: 'contact.form.notifications.error.title' })
      )
    }
  }, [error])
  
  useEffect(() => {
    if (result) {
      NotificationManager.success(
        formatMessage({ id: 'contact.form.notifications.success.message' }),
        formatMessage({ id: 'contact.form.notifications.success.title' })
      )
    }
  }, [result])

  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)} ref={formElement}>
        <Field
          fieldName="first-name"
          label={formatMessage({ id: 'contact.form.fields.first-name.label' })}
          placeholder={formatMessage({ id: 'contact.form.fields.first-name.placeholder' })}
          required
          errors={errors}
          ref={register({ required: true })}
        />
        <Field
          fieldName="last-name"
          label={formatMessage({ id: 'contact.form.fields.last-name.label' })}
          placeholder={formatMessage({ id: 'contact.form.fields.last-name.placeholder' })}
          required
          errors={errors}
          ref={register({ required: true })}
        />
        <Field
          fieldName="email"
          label={formatMessage({ id: 'contact.form.fields.email.label' })}
          placeholder={formatMessage({ id: 'contact.form.fields.email.placeholder' })}
          inputProps={{ type: 'email' }}
          required
          errors={errors}
          ref={register({ required: true, pattern: emailRegex })}
        />
        <Field
          fieldName="phone"
          label={formatMessage({ id: 'contact.form.fields.phone.label' })}
          placeholder={formatMessage({ id: 'contact.form.fields.phone.placeholder' })}
          inputProps={{ type: 'tel' }}
          required
          errors={errors}
          ref={register({ required: true, pattern: phoneRegex })}
        />
        <Field
          fieldName="company"
          label={formatMessage({ id: 'contact.form.fields.company.label' })}
          errors={errors}
          ref={register}
        />
        <Field
          fieldName="role"
          label={formatMessage({ id: 'contact.form.fields.role.label' })}
          errors={errors}
          ref={register}
        />
        <Field
          fieldName="message"
          placeholder={formatMessage({ id: 'contact.form.fields.message.placeholder' })}
          multiline
          errors={errors}
          ref={register}
        />
        <Note size="sm" weight="light">{formatMessage({ id: 'contact.form.note' })}</Note>
        <Buttons>
          <SubmitButton
            type="submit"
            value={formatMessage({ id: `contact.form.submit-button.${isSubmitting ? 'submitting' : 'default'}` })}
            disabled={isSubmitting}
          />
        </Buttons>
      </Form>
      <NotificationContainer />
    </Wrapper>
  )
}
